<template>
  <div ref="cascaderTree" class="ui-box-cascader">
    <el-cascader popper-class="cascader-tree" @change="cascaderChange" class="ui-box-from-input width-Multiple closesuffix" clearable :collapse-tags="collapseTags(selectValue)" v-model="selectValue" :placeholder="placeholder" :disabled="pageType==='see'" ref='cascaderRef' :show-all-levels="false" :options="cascaderOptions" :props="props">
    </el-cascader>
    <el-popover v-if="popoverShow" popper-class="cascaderPopoverTree" placement="top-start" title="" :width="popoverWidth" :offset="popoverWidth-25" trigger="click">
      <div class="popoverBox">
        <div class="popoverItem" v-for="i in popoverContent" :key="i.name">
          <el-row>
            <el-col>
              <div class="popoverTopName">{{i.name}}</div>
            </el-col>
          </el-row>
          <div v-for="j in i.children" :key="j.name">
            <el-row>
              <el-col :span="leftSpan">
                <div class="popoverParentName">{{j.name}}</div>
              </el-col>
              <el-col :span="24-leftSpan">
                <span class="popoverName" v-for="k in j.children" :key="k.name">{{k.name}}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <el-button class="view-button" slot="reference"><i class="el-icon-view"></i></el-button>
    </el-popover>
  </div>

</template>

<script>
export default {
  name: 'cascaderTree',
  props: {
    popoverWidth: {
      type: Number,
      default: 700
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    leftSpan: {
      type: Number,
      default: 2
    },
    props: Object,
    cascaderOptions: Array,
    cascaderValue: {
      type: Array,
      default: function () {
        return []
      }
    },
    pageType: String,
    collapseTagsNum: {
      type: Number,
      default: 5
    },
    isSee: Boolean
  },
  data() {
    return {
      selectValue: []
    }
  },
  watch: {
    popoverWidth(val) {
      this.$refs.cascaderTree.style.setProperty(
        '--width-popoverWidth',
        `${this.popoverWidth}px`
      )
    },
    cascaderValue(val, old) {
      if (val !== this.selectValue) {
        this.selectValue = val
      }
    },
    selectValue(val) {
      this.$emit('update:cascaderValue', val)
    }
  },
  computed: {
    popoverShow() {
      return (
        this.selectValue.length > 0 &&
        this.$refs['cascaderRef'] &&
        this.$refs['cascaderRef'].getCheckedNodes().length > 0
      )
    },
    popoverContent() {
      if (
        this.selectValue.length > 0 &&
        this.$refs['cascaderRef'] &&
        this.$refs['cascaderRef'].getCheckedNodes().length > 0
      ) {
        const nodes = this.$refs['cascaderRef'].getCheckedNodes()
        let nodes1 = []
        nodes.map((i) => {
          if (!i.hasChildren) {
            const name = i.label
            const parentName = i.parent.label
            const topName = i.parent.parent.label
            if (nodes1.some(({ name }) => name === topName)) {
              nodes1 = nodes1.map((j) => {
                if (j.name === topName) {
                  if (j.children.some(({ name }) => name === parentName)) {
                    j.children = j.children.map((k) => {
                      if (k.name === parentName) {
                        if (
                          !k.children.some(({ name: name1 }) => name1 === name)
                        ) {
                          k.children.push({
                            parentName: parentName,
                            name: name
                          })
                        }
                      }
                      return k
                    })
                  } else {
                    j.children.push({
                      name: parentName,
                      children: [
                        {
                          parentName: parentName,
                          name: name
                        }
                      ]
                    })
                  }
                }
                return j
              })
            } else {
              nodes1.push({
                name: topName,
                children: [
                  {
                    parentName: topName,
                    name: parentName,
                    children: [
                      {
                        parentName: parentName,
                        name: name
                      }
                    ]
                  }
                ]
              })
            }
          }
        })
        return nodes1
      }
      return ''
    }
  },
  methods: {
    cascaderChange(val) {
      this.$emit('change', val)
    },
    getNodeAllData() {
      if (
        this.selectValue.length > 0 &&
        this.$refs['cascaderRef'] &&
        this.$refs['cascaderRef'].getCheckedNodes().length > 0
      ) {
        const nodes = this.$refs['cascaderRef'].getCheckedNodes()
        return nodes
          .filter((i) => !i.hasChildren)
          .map((i) => {
            return {
              tbProvinceId: i.parent.parent.data.id,
              tbProvinceName: i.parent.parent.label,
              tbCityId: i.parent.data.id,
              tbCityName: i.parent.label,
              tbAreaId: i.data.id,
              tbAreaName: i.label
            }
          })
      }
      return []
    },
    collapseTags(val) {
      return val && val.length > this.collapseTagsNum ? true : false
    }
  },
  mounted() {
    this.$refs.cascaderTree.style.setProperty(
      '--width-popoverWidth',
      `${this.popoverWidth}px`
    )
    if (this.selectValue.length === 0 && this.cascaderValue.length > 0) {
      this.selectValue = this.cascaderValue
    }
  }
}
</script>

<style lang="scss" scoped>
$popoverWidth: var(--width-popoverWidth);
.ui-box-cascader {
  position: relative;
  .ui-box-from-input {
    height: 32px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    &.closesuffix {
      ::v-deep .el-input__suffix {
        display: none;
      }
    }
    &.width-Multiple {
      vertical-align: top;
      width: $popoverWidth;
    }

    ::v-deep .el-input__inner {
      vertical-align: top;
      height: 30px !important;
      line-height: 30px;
      border: none;
    }
  }
  ::v-deep .el-popover__reference-wrapper {
    position: absolute;
  }
  ::v-deep .view-button {
    border: none;
    background-color: rgba(0, 0, 0, 0);
    padding: 0;
    position: relative;
    left: -25px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}
</style>