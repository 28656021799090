/**
 * Created by Administrator on 2018/6/14.
 */
/**
 * 手机号验证
 * @param str
 * @returns {boolean}
 */
function isValidPhone(str) {
  const reg = /^1[3|4|5|7|8]\d{9}$/
  return reg.test(str)
}
/**
 * 电话号码（座机）验证
 * 验证规则：区号+号码，区号以0开头，3位或4位，号码由7位或8位数字组成，区号与号码之间可以无连接符，也可以“-”连接
 * @param str
 * @returns {boolean}
 */
function isValidFixedPhone(str) {
  const reg = /^0\d{2,3}-?\d{7,8}$/
  return reg.test(str)
}
/**
 * url验证
 * @param str
 * @returns {boolean}
 */
function isValidUrl(str) {
  const reg = /^((https|http|ftp|rtsp|mms)?:\/\/)+[A-Za-z0-9]+\.[A-Za-z0-9]+[\/=\?%\-&_~`@[\]\':+!]*([^<>\"\"])*$/
  return reg.test(str)
}
/**
 * ip地址验证
 * @param str
 * @returns {boolean}
 */
function isValidIP(str) {
  const reg = /([0-9]{1,3}\.{1}){3}[0-9]{1,3}/
  return reg.test(str)
}
/**
 * 邮箱验证
 * 验证规则：邮箱地址分成“第一部分@第二部分.第三部分”
 * @param str
 * @returns {boolean}
 */
function isValidEmail(str) {
  const reg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/
  return reg.test(str)
}
/**
 * 身份证号码验证
 * 验证规则：身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
 * @param str
 * @returns {boolean}
 */
function isValidCardNumber(str) {
  const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
  return reg.test(str)
}
/**
 * 验证字符串是否是数字（整形或浮点型）
 * 验证规则：第一部分.第二部分
 * @param str
 * @returns {boolean}
 */
function isValidNumber(str) {
  const reg = /^[0-9]+.?[0-9]*$/
  return reg.test(str)
}
/**
 * 验证汉字
 * @param str
 * @returns {boolean}
 */
function isValidChinese(str) {
  const reg = /^[\u4e00-\u9fa5]+$/
  return reg.test(str)
}
/**
 * 验证是否是数字和字母组合的字符串
 * @param str
 * @returns {boolean}
 */
function isValidCheckRate(str) {
  const reg = /^[0-9a-zA-Z]*$/
  return reg.test(str)
}

export default {

  ValidPhone(params) {
    return isValidPhone(params)
  },
  ValidFixedPhone(params) {
    return isValidFixedPhone(params)
  },
  ValidUrl(params) {
    return isValidUrl(params)
  },
  ValidIP(params) {
    return isValidIP(params)
  },
  ValidEmail(params) {
    return isValidEmail(params)
  },
  ValidCardNumber(params) {
    return isValidCardNumber(params)
  },
  ValidNumber(params) {
    return isValidNumber(params)
  },
  ValidChinese(params) {
    return isValidChinese(params)
  },
  ValidCheckRate(params) {
    return isValidCheckRate(params)
  }
}
