<template>
  <div class="ui-content">
    <div class="ui-box">
      <stepProcess v-if="form.tbType!==2" :status='stepProcessStatus' :stepNameList="stepProcess.stepNameList" :timeList="stepProcess.timeList"></stepProcess>
      <statusInfo :statusLabel='statusLabel' :statusText="statusMap[form.tbState]">
        <hireRefund :data.sync="form" :dialogVisible.sync="dialogVisible7"></hireRefund>
        <remarkOrder :data.sync="form" :dialogVisible.sync="dialogVisible"></remarkOrder>
        <updateConsigneeInfo :data.sync="form.orderShipping" :dialogVisible.sync="dialogVisible1"></updateConsigneeInfo>
        <sendStationInfo :dialogVisible.sync="dialogVisible2"></sendStationInfo>
        <orderTracking :data.sync="trackData" :dialogVisible.sync="dialogVisible3" title="订单跟踪"></orderTracking>
        <updateGoods :dialogVisible.sync="dialogVisible4" :addGoodsDialogVisible.sync="dialogVisible5"></updateGoods>
        <addGoods :dialogVisible.sync="dialogVisible5" :updateGoodDialogVisible.sync="dialogVisible4"></addGoods>
        <updateCostInfo @updateInfo="getById" :data.sync="form" :dialogVisible.sync="dialogVisible6"></updateCostInfo>
      </statusInfo>
      <orderBaseInfo :data.sync="form"></orderBaseInfo>
      <orderConsigneeInfo :data.sync="form"></orderConsigneeInfo>
      <orderGoodsInfo @updateInfo="getById" :data.sync="form"></orderGoodsInfo>
      <hireInfo v-if="form.orderHireVo" :data.sync="form.orderHireVo" :tbIsExtract="form.tbIsExtract"></hireInfo>
      <damageInfo v-if="form.changeLog" :hireLog.sync="form.changeLog"></damageInfo>
      <damageInfo v-if="form.returnLog" :hireLog.sync="form.returnLog"></damageInfo>
      <orderCostInfo :data.sync="form"></orderCostInfo>
      <orderOperateInfo :data.sync="form"></orderOperateInfo>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import statusInfo from '@/components/order/statusInfo'
import stepProcess from '@/components/order/stepProcess'
import orderBaseInfo from '@/components/order/orderDetail/baseInfo'
import orderConsigneeInfo from '@/components/order/orderDetail/consigneeInfo'
import orderGoodsInfo from '@/components/order/orderDetail/goodsInfo'
import orderCostInfo from '@/components/order/orderDetail/costInfo'
import orderOperateInfo from '@/components/order/orderDetail/operateInfo'
import orderTracking from '@/components/order/orderDetail/orderTracking'
import sendStationInfo from '@/components/order/orderDetail/sendStationInfo'
import updateConsigneeInfo from '@/components/order/orderDetail/updateConsigneeInfo'
import updateGoods from '@/components/order/orderDetail/updateGoods'
import updateCostInfo from '@/components/order/orderDetail/updateCostInfo'
import addGoods from '@/components/order/orderDetail/addGoods'
import remarkOrder from '@/components/order/orderDetail/remarkOrder'
import hireRefund from '@/components/order/orderDetail/hireRefund'
import hireInfo from '@/components/order/orderDetail/hireInfo'
import damageInfo from '@/components/order/orderDetail/damageInfo'
export default {
  name: 'orderorderListorderDetail',
  data () {
    return {
      stepProcessStatus: 1,
      statusLabel: '当前订单状态',
      statusList: [
        {
          id: '1',
          name: '待付款'
        },
        {
          id: '2',
          name: '待发货'
        },
        {
          id: '3',
          name: '已发货'
        },
        {
          id: '4',
          name: '已完成'
        },
        {
          id: '5',
          name: '已评价'
        },
        {
          id: '6',
          name: '已关闭'
        }
      ],
      stepProcess: {
        stepNameList: ['提交订单', '支付订单', '确认发货', '确认收货', '完成评价'],
        timeList: ['2020-10-10 12:12', '2020-10-10 12:12', '2020-10-10 12:12', '2020-10-10 12:12', '2020-10-10 12:12']
      },
      trackData: [],
      form: {
        orderHireVo: {
          tbPrice: ''
        },
        orderShipping: {}
      },
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      dialogVisible4: false,
      dialogVisible5: false,
      dialogVisible6: false,
      dialogVisible7: false,
      status: 1
    }
  },
  computed: {
    statusMap () {
      return this.statusList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    ...mapGetters([
      'lastBreadCrumb',
      'refreshPage'
    ])
  },
  methods: {
    confirmDelivery () {
      this.msgboxMixin(() => {
        this.$http.get('/order/confirmDelivery', { 'id': this.$route.query.tbId })
          .then(res => {
            if (res.code === 1000) {
              this.$message.success('提货成功')
              this.getById()
            } else {
              this.$message.error(res.message)
            }
          })
      }, `提货日期将设置为下单日期,确认通过${this.form.orderGoods[0].tbShopInfoName}提货?`)
    },
    confirmReceipt () {
      this.msgboxMixin(() => {
        this.$http.get('/order/confirmReceipt', { 'id': this.$route.query.tbId })
          .then(res => {
            if (res.code === 1000) {
              this.$message.success('收货成功')
              this.getById()
            } else {
              this.$message.error(res.message)
            }
          })
      }, '确认收货?')

    },
    closeOrders (ids) {
      this.$http.post('/order/batchCloseOrders', ids)
        .then(res => {
          if (res.code === 1000) {
            this.$message.success('关闭成功')
            this.getById()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getOrderDetailsTrack (code) {
      this.$http.get('/order/getOrderDetailsTrack', { orderCode: code })
        .then(res => {
          if (res.code === 1000) {
            this.trackData = res.data.map(i => {
              i.tbListMinFigure = i.tbListMinFigure ? i.tbListMinFigure.split(',') : []
              i.traces = i.traces.reverse()
              return i
            })
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getById () {
      this.$http.get('/order/getOrderDetailsById', { 'id': this.$route.query.tbId, orderType: this.$route.query.orderType })
        .then(res => {
          if (res.code === 1000) {
            if (res.data.tbState === 5) {
              const item = res.data.orderLogs.find(i => i.tbOperType === '订单评价')
              if (item?.createTime) {
                res.data.evaluateTime = item.createTime
              }
            }
            if (res.data.changeLog?.tbPicPath) {
              res.data.changeLog.tbPicPath = res.data.changeLog.tbPicPath.split(',').map(i => this.setUrlOss(i))
            }
            if (res.data.returnLog?.tbPicPath) {
              res.data.returnLog.tbPicPath = res.data.returnLog.tbPicPath.split(',').map(i => this.setUrlOss(i))
            }
            this.stepProcessStatus = res.data.tbState
            this.stepProcess.timeList = []
            const temp = ['createTime', 'tbPayTime', 'tbDeliverGoodsTime', 'tbSignTime', 'evaluateTime']
            let count = 1
            temp.forEach(i => {
              if (this.stepProcessStatus === 6 && !res.data[i]) {
                count = count + 1
              }
              this.stepProcess.timeList.push(res.data[i] && new Date(res.data[i]).format('yyyy-MM-dd hh:mm') || '')
            })
            if (this.stepProcessStatus === 6) {
              this.stepProcessStatus = this.stepProcessStatus - count
            }
            if ([3, 4].includes(res.data.tbState)) {
              this.getOrderDetailsTrack(res.data.tbOrderCode)
            }
            if (!res.data.orderShipping) {
              res.data.orderShipping = {}
            }
            this.form = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    }
  },
  components: {
    stepProcess,
    orderBaseInfo,
    orderConsigneeInfo,
    orderGoodsInfo,
    orderCostInfo,
    orderOperateInfo,
    statusInfo,
    orderTracking,
    sendStationInfo,
    updateConsigneeInfo,
    updateGoods,
    addGoods,
    updateCostInfo,
    remarkOrder,
    hireRefund,
    hireInfo,
    damageInfo
  },
  watch: {
    $route (to, from) {
      if (to.path === '/order/orderList/orderDetail' && this.refreshPage) {
        this.getById()
        this.$store.dispatch('setRefreshPage', false)
      }
    }
  },
  created () {
    this.getById()
  },
  mounted () { }
}
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    .ui-order-status-button {
      margin: 24px 24px 0 0;
      float: right;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      padding: 5px 16px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &:focus {
        outline: none;
      }
    }
  }
}
</style>