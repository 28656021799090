<template>
  <el-dialog title="修改密码" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="402px" :before-close="dialogBeforeClose">
    <div class="ui-seach">
      <div class="ui-label">密码</div>
      <el-input class="ui-input" placeholder="请输入密码" v-model="password"></el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
      <el-button class="ui-box-submit" type="primary" @click="save">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'updatePassword',
  props: {
    tbId: String,
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      password: '',
    }
  },
  watch: {
    dialogVisible (val) {
      if (val && this.tbId) {
        this.getById(this.tbId)
      }
    }
  },
  computed: {

  },
  methods: {
    save () {
      this.msgboxMixin(() => {
        this.$emit('update:dialogVisible', false)
        this.$http.post(`/serviceUser/updatePwd`, {
          tbId: this.tbId,
          tbPassword: this.password
        })
          .then(async res => {
            if (res.code === 1000) {
              this.$message.success('修改成功')
            } else {
              this.$message.error(res.message)
            }
          })
      }, '确认修改密码吗？')
    },
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
::v-deep .ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
  }
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  .ui-seach {
    margin-left: 24px;
    margin-top: 35px;
    .ui-label {
      display: inline-block;
      margin-right: 30px;
    }
    .ui-seachBox-reset {
      margin-left: 24px;
      border-radius: 2px;
      padding: 8px 15px;
      &:focus {
        outline: none;
      }
    }
    .ui-input {
      width: 192px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      ::v-deep .el-input-group__append {
        padding: 0 7px;
        border: none;
        background: #ffffff;
        border-left: 1px solid rgba(0, 0, 0, 0.15);
        ::v-deep .el-button {
          &:focus {
            outline: none;
          }
        }
      }
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-input__inner {
        height: 30px;
        line-height: 30px;
        border: none;
        &::-webkit-input-placeholder {
          color: #606266;
        }
        &::-moz-placeholder {
          color: #606266;
        }
        &::-ms-input-placeholder {
          color: #606266;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  .ui-table {
    margin: 30px 24px 0;
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}

::v-deep .ui-box-dialog {
  border-radius: 12px;
  .el-dialog__title {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #333333;
    line-height: 16px;
  }
  .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .el-dialog__body {
    padding: 0;
  }
  .ui-seach {
    margin-left: 24px;
    margin-top: 35px;
    .ui-label {
      display: inline-block;
      margin-right: 30px;
    }
    .ui-seachBox-reset {
      margin-left: 24px;
      border-radius: 2px;
      padding: 8px 15px;
      &:focus {
        outline: none;
      }
    }
    .ui-input {
      width: 192px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      .el-input-group__append {
        padding: 0 7px;
        border: none;
        background: #ffffff;
        border-left: 1px solid rgba(0, 0, 0, 0.15);
        .el-button {
          &:focus {
            outline: none;
          }
        }
      }
      .el-icon-arrow-up,
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        border: none;
        &::-webkit-input-placeholder {
          color: #606266;
        }
        &::-moz-placeholder {
          color: #606266;
        }
        &::-ms-input-placeholder {
          color: #606266;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  .ui-table {
    margin: 30px 24px 0;
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
</style>