<template>
  <el-dialog title="修改费用信息" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="1060px" :before-close="dialogBeforeClose">
    <div class="ui-box-tableList">
      <el-table :data="list" border style="width: 100%" :header-cell-style="{background: '#FFFFFF',color: '#000000','font-weight': 500,'font-family': 'Microsoft YaHei'}">
        <el-table-column align="center" width="95" prop="goodsOriginalPrice" label="商品原价">
        </el-table-column>
        <el-table-column align="center" prop="tbFreight" label="运费">
          <template slot-scope="{row}">
            <el-input-number size="mini" v-model="row.tbFreight" :min="0"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column align="center" width="95" prop="discountPrice" label="折扣金额">
        </el-table-column>
        <el-table-column align="center" width="95" prop="integral" label="积分抵扣">
        </el-table-column>
        <el-table-column align="center" width="95" prop="discountActivity" label="优惠活动">
        </el-table-column>
        <el-table-column align="center" width="95" prop="coupon" label="优惠券">
        </el-table-column>
        <el-table-column align="center" width="95" prop="member" label="会员抵扣">
        </el-table-column>
        <el-table-column align="center" prop="tbReductionPrice" label="其他折扣">
          <template slot-scope="{row}">
            <el-input-number size="mini" v-model="row.tbReductionPrice" :min="0"></el-input-number>
          </template>
        </el-table-column>
        <el-table-column align="center" width="92" prop="actualPrice" label="合计金额">
          <template>
            {{allCost()}}
          </template>
        </el-table-column>

      </el-table>
    </div>
    <div class="ui-box-bottom">
      <div class="ui-box-costInfo-all">合计优惠金额：￥{{allDiscount()}}</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
      <el-button class="ui-box-submit" type="primary" @click="save">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'updateGoods',
  props: {
    data: Object,
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      selectRow: [],
      textarea: '',
      options: []
    }
  },
  computed: {

    list () {
      if (this.data && this.data.costDetailed) {
        return [this.data.costDetailed]
      } else {
        return []
      }
    }
  },
  methods: {
    save () {
      const from = {
        orderId: this.$route.query.tbId,
        tbReductionPrice: this.list[0].tbReductionPrice || 0,
        tbPrice: this.allCost(),
        tbFreight: this.list[0].tbFreight || 0
      }
      this.$http.post('/order/updateOrderPrice', from)
        .then(res => {
          if (res.code === 1000) {
            this.$message.success('修改订单费用成功')
            this.$emit('updateInfo')
            this.dialogBeforeClose()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    allCost () {
      if (this.list.length === 1) {
        const { goodsOriginalPrice, tbFreight = 0 } = this.list[0]
        const cost = Number(goodsOriginalPrice) + Number(tbFreight) - this.allDiscount()
        return cost > 0 ? cost : 0
      } else {
        return ''
      }
    },
    allDiscount () {
      if (this.list.length === 1) {
        const { discountPrice, integral, discountActivity, coupon, member, tbReductionPrice = 0 } = this.list[0]
        const cost = Number(discountPrice) + Number(integral) + Number(discountActivity) + Number(coupon) + Number(member) + Number(tbReductionPrice)
        return cost > 0 ? cost : 0

      } else {
        return ''
      }
    },
    addGoods () {
      this.$emit('update:dialogVisible', false)
    },
    handleSelectionChange (val) {
      this.selectRow = val
    },
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .ui-box-tableList {
    .ui-box-tableList-img {
      width: 51px;
      height: 51px;
      background: #d8d8d8;
      margin-left: 13px;
    }
  }
  .ui-box-bottom {
    margin-bottom: 30px;
    .ui-box-button {
      padding: 8px 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin-top: 15px;
      float: left;
      &:focus {
        outline: none;
      }
    }
    .ui-box-costInfo-all {
      width: 200px;
      height: 22px;
      float: right;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
      margin-top: 15px;
      text-align: right;
    }
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
//
</style>