import Vue from 'vue'

Vue.directive('has', {
  //按钮权限
  inserted (el, binding, vnode) {
    const btns = vnode.context.$route.meta.btnPermission || []
    if (binding.value) {
      const has = btns.some(item => {
        return item.tbCode === binding.value
      })
      if (!has) {
        el.remove()
      }
    }
  }
})