const mixin = {
  data() {
    return {}
  },
  methods: {
    errMinxin(callback, title = '错误', message) {
      this.$msgbox({
        title: title,
        message: message,
        type: 'error',
        showCancelButton: !0,
        showConfirmButton: !0,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            done()
            setTimeout(() => {
              instance.confirmButtonLoading = false
            }, 300)
          } else {
            done()
          }
        }
      }).then((action) => {
        callback && Reflect.apply(callback, this, {})
      })
    },
    exportMixin(url, name = 'export.xlsx') {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    //消息提示 默认删除提示
    msgboxMixin(callback, message = '确定删除数据？') {
      this.$msgbox({
        title: '提示',
        message: message,
        type: 'warning',
        showCancelButton: !0,
        showConfirmButton: !0,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '执行中...'
            done()
            setTimeout(() => {
              instance.confirmButtonLoading = false
            }, 300)
          } else {
            done()
          }
        }
      }).then((action) => {
        callback && Reflect.apply(callback, this, {})
      })
    }
  }
}
export default mixin


