<template>
  <el-dialog :title="title" top="30vh" custom-class="ui-box-dialog orderTrackingDialog" :visible.sync="dialogVisible" width="600px" :before-close="dialogBeforeClose">
    <div v-for="(item,i) in data" :key="'parcelInfo'+i">
      <div class="ui-box-dialog-parcelInfo" v-if="isShow">
        <div class="ui-box-dialog-parcelInfo-text">包裹{{i+1}}</div>
        <div class="ui-box-dialog-parcelInfo-text1">{{item.tbCompanyName}}，{{item.tbExpressCode}}</div>
      </div>
      <img v-for="(url,j) in item.tbListMinFigure" style="width:60px;height60px;margin-right:16px" :key="'parcelImg'+j" class="ui-box-dialog-img" :src="getUrl(url)" @error="errorImg" />
      <div style="margin-top:11px;">
        <el-steps direction="vertical" class="ui-box-dialog-steps" :active="item.traces.length" finish-status="success">
          <el-step v-for="(tracesInfo,k) in item.traces" :key="'traces'+k" :title="tracesInfo.AcceptStation" :description="tracesInfo.AcceptTime"></el-step>
          <!-- <el-step title="订单付款成功" description="2020-10-10 12：12：20"></el-step>
          <el-step title="在北京市进行下级地点扫描，等待付款" description="2020-10-10 12：12：20"></el-step>
          <el-step title="在分拨中心广东深圳公司时行卸车扫描，等待付款" description="2020-10-10 12：12：20"></el-step>
          <el-step title="到达目的地网点广东深圳公司，快件将很快进行派送" description="2020-10-10 12：12：20"></el-step>
          <el-step title="订单已签收，期待再次为您服务" description="2020-10-10 12：12：20"></el-step> -->
        </el-steps>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'orderTracking',
  props: {
    isShow: {
      type: Boolean,
      default: true
    },
    title: String,
    data: Array,
    tbId: String,
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      textarea: ''
    }
  },
  computed: {

  },
  methods: {
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
// >>> .orderTrackingDialog
//   >>> .el-dialog__header
//   >>> .el-dialog__headerbtn:focus {
//   outline: none;
// }
// >>> .orderTrackingDialog >>> .el-dialog__body {
//   overflow-y: auto;
//   height: 500px;
// }
// >>> .orderTrackingDialog
//   >>> .el-dialog__body
//   >>> .ui-box-dialog-steps
//   >>> .el-step__title.is-success {
//   font-size: 16px;
//   font-family: Microsoft YaHei;
//   font-weight: 400;
// }
::v-deep .orderTrackingDialog {
  border-radius: 12px;
  .el-dialog__header {
    .el-dialog__headerbtn:focus {
      outline: none;
    }
  }
  .el-dialog__body {
    overflow-y: auto;
    height: 500px;
    .ui-box-dialog-parcelInfo {
      height: 54px;
      background: #f2f6fc;
      opacity: 0.77;
      margin-bottom: 10px;
      .ui-box-dialog-parcelInfo-text {
        display: inline-block;
        margin: 16px 30px 16px 16px;
        width: 70px;
        height: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1890ff;
        line-height: 22px;
      }
      .ui-box-dialog-parcelInfo-text1 {
        display: inline-block;
        margin: 16px 0;
        width: auto;
        height: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1890ff;
        line-height: 22px;
      }
    }
    .ui-box-dialog-steps {
      .el-step:last-child {
        .el-step__title,
        .el-step__description {
          color: #333333;
        }
      }
      .el-step__title.is-success {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
      .el-step__description {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
    .ui-box-cancel {
      padding: 7px 26px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      line-height: 22px;
      &:focus {
        outline: none;
      }
    }
    .ui-box-submit {
      padding: 7px 26px;
      background: #3d7fff;
      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      &:focus {
        outline: none;
      }
    }
  }
}

::v-deep .orderTrackingDialog {
  border-radius: 12px;
  ::v-deep .el-dialog__header {
    ::v-deep .el-dialog__headerbtn {
      &:focus {
        outline: none;
      }
    }
  }
  ::v-deep .el-dialog__body {
    overflow-y: auto;
    height: 500px;
    .ui-box-dialog-parcelInfo {
      height: 54px;
      background: #f2f6fc;
      opacity: 0.77;
      margin-bottom: 10px;
      .ui-box-dialog-parcelInfo-text {
        display: inline-block;
        margin: 16px 30px 16px 16px;
        width: 70px;
        height: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1890ff;
        line-height: 22px;
      }
      .ui-box-dialog-parcelInfo-text1 {
        display: inline-block;
        margin: 16px 0;
        width: auto;
        height: 22px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1890ff;
        line-height: 22px;
      }
    }
    .ui-box-dialog-steps {
      ::v-deep .el-step:last-child {
        ::v-deep .el-step__title,
        ::v-deep .el-step__description {
          color: #333333;
        }
      }
      ::v-deep .el-step__title.is-success {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
      ::v-deep .el-step__description {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
    .ui-box-cancel {
      padding: 7px 26px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      line-height: 22px;
      &:focus {
        outline: none;
      }
    }
    .ui-box-submit {
      padding: 7px 26px;
      background: #3d7fff;
      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      &:focus {
        outline: none;
      }
    }
  }
}
</style>