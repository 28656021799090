<template>
  <el-dialog title="修改商品信息" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="1000px" :before-close="dialogBeforeClose">
    <div class="ui-box-tableList">
      <el-table :data="tableList" border style="width: 100%" :header-cell-style="{background: '#FFFFFF',color: '#000000','font-weight': 500,'font-family': 'Microsoft YaHei'}" @selection-change="handleSelectionChange">
        <el-table-column align="center" type="selection" width="55">
        </el-table-column>
        <el-table-column align="center" width="98" prop="1" label="商品图片">
          <template>
            <div class="ui-box-tableList-img"></div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="2" label="商品名称">
        </el-table-column>
        <el-table-column align="center" width="152" prop="4" label="规格">
        </el-table-column>
        <el-table-column align="center" width="98" prop="4" label="原价">
        </el-table-column>
        <el-table-column align="center" width="98" prop="4" label="折扣价">
        </el-table-column>
        <el-table-column align="center" width="108" prop="num" label="数量">
          <template slot-scope="{row}">
            <el-input v-model="row['num']"></el-input>
          </template>
        </el-table-column>
        <el-table-column align="center" width="114" prop="5" label="合计金额">
        </el-table-column>
      </el-table>
    </div>
    <div class="ui-box-bottom">
      <el-button class="ui-box-button" @click="addGoods">添加商品</el-button>
      <el-button class="ui-box-button">删除商品</el-button>
      <div class="ui-box-costInfo-all">合计优惠金额：￥550</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
      <el-button class="ui-box-submit" type="primary" @click="dialogBeforeClose">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'updateGoods',
  props: {
    dialogVisible: {
      type: Boolean
    },
    addGoodsDialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      selectRow: [],
      tableList: [],
      textarea: '',
      options: []
    }
  },
  computed: {

  },
  methods: {
    addGoods () {
      this.$emit('update:dialogVisible', false)
      this.$emit('update:addGoodsDialogVisible', true)
    },
    handleSelectionChange (val) {
      this.selectRow = val
    },
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .ui-box-tableList {
    .ui-box-tableList-img {
      width: 51px;
      height: 51px;
      background: #d8d8d8;
      margin-left: 13px;
    }
  }
  .ui-box-bottom {
    margin-bottom: 30px;
    .ui-box-button {
      padding: 8px 15px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      margin-top: 15px;
      float: left;
      &:focus {
        outline: none;
      }
    }
    .ui-box-costInfo-all {
      width: 200px;
      height: 22px;
      float: right;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
      margin-top: 15px;
      text-align: right;
    }
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
</style>