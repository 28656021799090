<template>
  <div class="ui-box-item">
    <titleComponents :show.sync="show" name="订单信息"></titleComponents>
    <div class="itemInfo-detail" v-show="show">
      <table class="ui-box-table">
        <tr>
          <th style="width:13%">订单编号</th>
          <th>商品名称</th>
          <th style="width:14%">下单时间</th>
          <th style="width:8%">收货人</th>
          <th>自提点</th>
          <th style="width:8%">订单状态</th>
          <th style="width:11%">详情</th>
        </tr>
        <tr v-for="item in data.orderList" :key="item.tbId">
          <td>{{item.tbOrderCode}}</td>
          <td>{{item.tbOrderGoodsName}}</td>
          <td>{{item.createTime}}</td>
          <td>{{item.tbLinkMan}}</td>
          <td>{{item.tbShopName}}</td>
          <td>{{getStatusMap(item)}}</td>
          <td>
            <el-button type="primary" @click="toOrderDetail(item.tbId)" v-has="pathCode+':orderDetail'">查看详情</el-button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import titleComponents from '@/components/mother/title'
export default {
  name: 'orderInfo',
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    pathCode: String
  },
  data () {
    return {
      statusList: [
        {
          id: '1',
          name: '待付款'
        },
        {
          id: '2',
          name: '待发货'
        },
        {
          id: '3',
          name: '已发货'
        },
        {
          id: '4',
          name: '已完成'
        },
        {
          id: '5',
          name: '已评价'
        },
        {
          id: '6',
          name: '已关闭'
        },
        {
          id: '7',
          name: '交易成功'
        }
      ],
      show: true
    }
  },
  created () {

  },
  computed: {
    statusMap () {
      return this.statusList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    ...mapGetters(['lastBreadCrumb', 'refreshPage'])
  },
  methods: {
    toOrderDetail (tbId) {
      this.openTab('订单详情', 'orderDetail', `/mother/orderDetail`, { tbId: tbId, orderType: 1 })
    },
    getStatusMap (row) {
      if (row.tbIsExtract && row.tbState == 3) {
        return '待自提'
      } else {
        return this.statusMap[row.tbState]
      }
    },
  },
  components: {
    titleComponents
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  margin: 20px 24px 0;
  background: rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  width: calc(100% - 48px);
  .itemInfo-detail {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .ui-box-table {
      table-layout: fixed;
      text-align: center;
      width: 100%;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
      border-collapse: collapse;
      border-style: hidden;
      box-shadow: 0 0 0 1px #e0e0e0;
      overflow: hidden;
      tr {
        border: 1px solid #e0e0e0;
        height: 54px;
        padding: 15px 0;
        line-height: 22px;
        th {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 700;
          color: #000000;
          text-align: center;
          border: 1px solid #e0e0e0;
        }
        td {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border: 1px solid #e0e0e0;
        }
      }
    }
  }
}
</style>
     