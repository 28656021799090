<template>
  <div class="paging-container">
    <div class="ui-pagination">
      <slot></slot>
      <div class="ui-div-font2">
        <el-select :popper-append-to-body="true" size="medium" class="ui-select-pageSize" v-model="size" @change="pagingSizeChange">
          <el-option v-for="item in pageSizes" :key="item" :label="item+'条/页'" :value="item"></el-option>
        </el-select>
        <span class="ui-div-font3">跳至</span>
        <el-input class="ui-div-input" @change="toPage" type="number" title="值必须大于或等于1" v-model="inputPage" auto-complete="off"></el-input>
        <span class="ui-div-font">页</span>
        <!-- <button @click="toPage" class="ui-button-Page">GO</button> -->
      </div>
      <el-pagination :pager-count="pagerCount" @size-change="pagingSizeChange" @current-change="pagingCurrentChange" :current-page="currentPage" :page-size="pageSize" background layout="prev, pager, next" :total="total">
      </el-pagination>
      <div class="ui-div-font1">第 {{ start }}~{{ end }} 条 / 共 {{ total }} 条</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const _this = this
    return {
      inputPage: 1, //输入框想要跳转的页
      pagingSizeChange(value) {
        _this.inputPage = 1
        _this.$emit('size-change', value)
      },
      pagingCurrentChange(value) {
        _this.inputPage = value == 0 ? 1 : value
        _this.$emit('current-change', value)
      },
      sccs: false,
      size: this.pageSize.toString() + '条/页'
    }
  },
  props: {
    currentPage: Number,
    pageSize: Number,
    total: Number,
    pageSizes: Array,
    pagerCount: {
      type: Number,
      default: 7
    }
  },
  mounted: function () { },
  computed: {
    start: function () {
      return this.total == 0 ? 0 : (this.currentPage - 1) * this.pageSize + 1
    },
    end: function () {
      const end = this.currentPage * this.pageSize
      return end < this.total ? end : this.total
    },
    maxPage: function () {
      return Math.ceil(this.total / this.pageSize)
    }
  },
  methods: {
    init() {
      this.inputPage = 1
    },
    isNotEmpty(obj) {
      if (typeof obj === 'undefined') return false
      if (typeof obj === 'number' && obj !== 0) return true
      if (obj === null) return false
      if (Object.keys(obj).length === 0) return false
      return true
    },
    toPage() {
      if (this.isNotEmpty(this.inputPage)) {
        if (parseInt(this.inputPage) <= this.maxPage) {
          this.pagingCurrentChange(parseInt(this.inputPage))
        } else {
          this.pagingCurrentChange(parseInt(this.maxPage))
        }
      }
    }
  },
  created() { },
  watch: {
    size(value) {
      this.inputPage = 1
      this.$emit('size-change', value)
    },
    total(value) {
      if (!value) {
        this.inputPage = 1
      } else if (this.currentPage > this.maxPage) {
        this.inputPage = 1
        this.toPage()
      }
    }
  }
}
</script>

<style>
/*分页栏样式*/
.paging-container {
  width: calc(100% - 48px);
  margin: 24px 24px 0;
  float: none;
}
.ui-pagination {
  text-align: left;
}
.paging-container
  .ui-pagination
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #e4e7ed;
  color: #fff;
}
.ui-select-pageSize {
  width: 110px !important;
  height: 32px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.paging-container .ui-select-pageSize .el-input__inner {
  height: 30px;
  line-height: 30px;
  border: none;
}
.paging-container .ui-select-pageSize .el-input__suffix {
  top: 3px;
}
.paging-container .ui-pagination .el-pagination.is-background .el-pager li {
  border: 1px solid #e4e7ed;
  border-radius: 3px;
}

.paging-container .ui-pagination .el-pagination.is-background .btn-prev {
  border: 1px solid #e4e7ed;
}

.paging-container .ui-pagination .el-pagination.is-background .btn-next {
  border: 1px solid #e4e7ed;
}

.ui-div-font2 .el-input .el-input__inner::-webkit-outer-spin-button,
.ui-div-font2 .el-input .el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.ui-div-font2 .el-input .el-input__inner:focus {
  border-color: #e4e7ed !important;
}

.ui-span-font1 {
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;
}

.ui-div-font1 {
  text-align: right;
  float: right;
  width: 200px;
  height: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  margin: 5px 0;
}

.ui-div-font2 {
  display: block;
  font-size: 14px;
  font-weight: 500;
  float: right;
}

.ui-div-font2 .el-input {
  outline: none;
}

.ui-button-Page {
  background-color: #f4f4f5;
  border-right: 1px solid #dcdfe6;
  border-top: 1px solid #dcdfe6;
  border-bottom: 1px solid #dcdfe6;
  height: 28px;
  line-height: 28px;
  width: 24px;
  font-size: 12px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -5px;
  border-left: none;
}

.ui-button-Page:focus {
  outline: none;
}
.ui-div-font3 {
  width: 37px;
  height: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  display: inline-block;
  margin-left: 16px;
}
.ui-div-font {
  width: 14px;
  height: 22px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  margin-left: 8px;
}
.ui-div-input,
.ui-div-input .el-input__inner {
  width: 48px !important;
  height: 32px;
  background: #ffffff;
}
.ui-div-input .el-input__inner {
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.ui-div-input .el-input__inner::-webkit-outer-spin-button,
.ui-div-input .el-input__inner::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.ui-div-input .el-input__inner[type="number"] {
  -moz-appearance: textfield;
}
.el-pager li,
.el-pagination button {
  height: 32px;
  line-height: 32px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.paging-container .el-pagination {
  padding: 0 16px;
  float: right;
}
</style>
