import '@/directive/chartResize'
import { fitChartSize } from '@/utils/dataUtil.js'
import * as echarts from 'echarts'
import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import App from './App.vue'
import breadCrumb from './components/breadCrumb'
import echartsComponent from './components/echarts'
import eluploadLocal from './components/elupload'
import pagination from './components/pagination'
import tabs from './components/tabs'
import tabsNav from './components/tabsNav'
import './directive/btnPermission.js'
import './directive/chartResize.js'
import './directive/focus.js'
import './directive/int.js'
import http from './http'
import Mixin from './mixin'
import './plugins/element.js'
import router from './router'
import store from './store'
import './utils/date.js'
import globalFunction from './utils/globalFunction'
import customCheck from './utils/validate'
Vue.use(AsyncComputed)
Vue.use(eluploadLocal)
Vue.use(tabs)
Vue.use(breadCrumb)
Vue.use(pagination)
Vue.use(tabsNav)
Vue.use(globalFunction)
Vue.mixin(Mixin)
Vue.use(echartsComponent)
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.prototype.fitChartSize = fitChartSize
Vue.prototype.$http = http
Vue.prototype.$customCheck = customCheck
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
Vue.config.errorHandler = function (err, vm, info) {
  if (err.message === "Cannot read property 'level' of null" && vm?.$vnode?.componentOptions?.tag === 'el-cascader-panel') {
    //
  } else {
    throw err
  }
}