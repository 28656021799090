<template>
  <div class="ui-box-item">
    <titleComponents :show.sync="show" name="胎心监测"></titleComponents>
    <div class="itemInfo-detail" v-show="show">
      <table class="ui-box-table">
        <tr>
          <th>测量时间</th>
          <th>测量时长</th>
          <th>测量数值</th>
          <th>测量结果</th>
        </tr>
        <tr v-if="!isToDay">
          <td>今日暂无</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-for="item in data.cardiacDevices" :key="item.tbId">
          <td>{{item.createTime}}</td>
          <td> {{`${getTimeLength(item.timeLength)}`}}</td>
          <td>{{Number(item.tbCardiacRate)+'bpm'}}</td>
          <td>{{item.tbState}}</td>
        </tr>
      </table>
      <div class="footer">
        <el-button type="primary" @click="toCardiacList" v-has="pathCode+':toCardiacList'">查看全部</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import titleComponents from '@/components/mother/title'
export default {
  name: 'cardiacInfo',
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    pathCode: String
  },
  data () {
    return {
      show: true
    }
  },
  computed: {
    isToDay () {
      if (this.data?.cardiacDevices?.length > 0) {
        const time = this.data?.cardiacDevices[0].createTime
        return new Date().toDateString() === new Date(time).toDateString()
      }
      return false
    },
    ...mapGetters(['lastBreadCrumb', 'refreshPage'])
  },
  created () {

  },
  methods: {
    getTimeLength (length) {
      if (length == 0) {
        return 0
      } else if (length < 60) {
        return length + '秒'
      } else {
        return `${Math.floor(length / 60)}分钟${length % 60 ? length % 60 + "秒" : ""}`
      }
    },
    toCardiacList () {
      this.openTab('胎心数据', 'heart', `/monitor/heart`, { phone: this.data.phone })
    }
  },
  components: {
    titleComponents
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  margin: 20px 24px 0;
  background: rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  width: calc(100% - 48px);
  .itemInfo-detail {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .footer {
      margin-top: 20px;
      width: 100%;
      text-align: right;
    }
    .ui-box-table {
      table-layout: fixed;
      text-align: center;
      width: 100%;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
      border-collapse: collapse;
      border-style: hidden;
      box-shadow: 0 0 0 1px #e0e0e0;
      overflow: hidden;
      tr {
        border: 1px solid #e0e0e0;
        height: 54px;
        line-height: 54px;
        th {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 700;
          color: #000000;
          text-align: center;
          border: 1px solid #e0e0e0;
        }
        td {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border: 1px solid #e0e0e0;
        }
      }
    }
  }
}
</style>
     