<template>
  <div class="ui-box-item">
    <titleComponents :show.sync="show" name="基础信息"></titleComponents>
    <div class="itemInfo-detail" v-show="show">
      <div class="baseInfo-content">
        <div class="text">昵称：{{data.nickname||''}}</div>
        <div class="text">手机号：{{data.phone||''}}</div>
        <div class="text">宝宝状态：{{data.currentGeneration===1?'孕':'已出生'}}{{data.currentGeneration===1?prepareProduction:babyBornTime}}</div>
      </div>
      <table class="ui-box-table">
        <tr>
          <th style="width:12%">专属医生</th>
          <th style="width:14%">电话</th>
          <th style="width:9%">科室</th>
          <th style="width:11%">职称</th>
          <th style="width:20%">省市区</th>
          <th>医院</th>
        </tr>
        <tr>
          <td>{{doctor?.tbName||''}}</td>
          <td>{{doctor?.tbPhone||''}}</td>
          <td>{{doctor?.tbDepartmentName||''}}</td>
          <td>{{doctor?.tbProfessionTitleName||''}}</td>
          <td>{{doctor?.tbProvinceName + doctor?.tbCityName+ doctor?.tbAreaName||''}}</td>
          <td>{{doctor?.tbHospitalName||''}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import titleComponents from '@/components/mother/title'
export default {
  name: 'baseInfo',
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    doctor: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      show: true
    }
  },
  created () {

  },
  computed: {
    prepareProduction () {
      if (this.data.prepareProduction) {
        const date = new Date(this.data.prepareProduction)
        date.setDate(date.getDate() - 280)
        let day = this.getDaysBetweenDates(new Date(), date)
        if (day < -280) {
          day = -280;
        }
        const days = Math.abs(day);
        const week = Math.floor(days / 7);
        const d = days % 7;
        if (week > 41) {
          return "41周" + "+" + "6天";
        }
        return week + "周" + "+" + d + "天";
      }
      return ''
    },
    babyBornTime () {
      if (this.data.babyBornTime) {
        const date = new Date(this.data.babyBornTime)
        let day = this.getDaysBetweenDates(date, new Date())
        const days = Math.abs(day);
        if (days >= 365) {
          const year = Math.floor(days / 365);
          const temp = days % 365;
          const month = Math.floor(temp / 30);
          const d = temp % 30;
          return year + "岁+" + month + "个月+" + d + "天";
        } else if (days >= 30) {
          const month = Math.floor(days / 30);
          const d = days % 30;
          return month + "个月+" + d + "天";
        } else {
          return days + "天";
        }
      }
      return ''
    }
  },
  methods: {
    getWeekDay () {

    },
    getDaysBetweenDates (date1, date2) {
      var time1 = date1.getTime();
      var time2 = date2.getTime();
      var diff = time1 - time2;
      var days = Math.ceil(diff / (1000 * 3600 * 24));
      return days;
    }
  },
  components: {
    titleComponents
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  margin: 20px 24px 0;
  background: rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  width: calc(100% - 48px);
  .itemInfo-detail {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .baseInfo-content {
      display: flex;
      align-content: center;
      margin-bottom: 25px;
      .text {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 22px;
        margin-right: 60px;
      }
    }
    .ui-box-table {
      table-layout: fixed;
      text-align: center;
      width: 100%;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
      border-collapse: collapse;
      border-style: hidden;
      box-shadow: 0 0 0 1px #e0e0e0;
      overflow: hidden;
      tr {
        border: 1px solid #e0e0e0;
        height: 54px;
        line-height: 54px;
        th {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 700;
          color: #000000;
          text-align: center;
          border: 1px solid #e0e0e0;
        }
        td {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border: 1px solid #e0e0e0;
        }
      }
    }
  }
}
</style>
     