<template>
  <div class="ui-box-title">
    <div class="title-name">{{name}}</div>
    <div class="show-box" @click="updateShow">
      <div class="title-name marginRight10">{{show?'收起':'展开'}}</div>
      <i :class="show?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: 'titleComponents',
  props: {
    name: String,
    show: Boolean
  },
  data () {
    return {
      tbGoodsSort: ''
    }
  },
  created () {

  },
  methods: {
    updateShow () {
      this.$emit('update:show', !this.show)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-box-title {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-name {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
    line-height: 24px;
    &.marginRight10 {
      margin-right: 10px;
    }
  }
  .show-box {
    display: flex;
    align-items: center;
  }
}
</style>
     