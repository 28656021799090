<template>
  <div class="ui-content">
    <div class="ui-box">
      <el-form ref="form" class="ui-box-from-motherDetail" label-position="right" :model="form" label-width="150px">
        <baseInfo :data.sync="form" :doctor="form.tbDoctor"></baseInfo>
        <maintainInfo :data="form" :pathCode="pathCode" @init="init"></maintainInfo>
        <orderInfo :pathCode="pathCode" :data="form"></orderInfo>
        <cardiacInfo :pathCode="pathCode" :data="form"></cardiacInfo>
        <jaundiceInfo :pathCode="pathCode" :data="form"></jaundiceInfo>
        <logInfo :data="form"></logInfo>
      </el-form>
    </div>
  </div>
</template>

<script>
const files = require.context('/', false, /\.vue$/);
const suffixList = files.id
  .split(' ')[0]
  .split('/')
  .filter((a, b) => b > 2);
const fileName = 'allDetail';
const controller = 'user';
const parentName = '客户信息';
import cal from '@/utils/calculation';
import { mapGetters } from 'vuex';
import maintainInfo from '@/components/mother/maintainInfo'
import baseInfo from '@/components/mother/baseInfo'
import orderInfo from '@/components/mother/orderInfo'
import cardiacInfo from '@/components/mother/cardiacInfo'
import jaundiceInfo from '@/components/mother/jaundiceInfo'
import logInfo from '@/components/mother/logInfo'
export default {
  name: `${suffixList.join('')}${fileName}`,
  data () {
    const _this = this;
    return {
      pathCode: `${suffixList.join('')}${fileName}`,
      loading: false,
      filePath: ['', ...suffixList, fileName].join('/'),
      fileName: fileName,
      jaundiceData: [
        [0, 0],
        [3, 0],
        [10, 1.5],
        [20, 3.3],
        [30, 4.9],
        [40, 6.1],
        [50, 7.1],
        [60, 7.9],
        [70, 8.5],
        [80, 9.0],
        [90, 9.4],
        [100, 9.6],
        [110, 9.8],
        [120, 10.0],
        [130, 9.9],
        [140, 9.7],
        [150, 9.4],
        [160, 9.1],
        [168, 8.8],
        [720, 8.8]
      ],
      form: {
        cardiacDevices: [],
        jaundiceDevices: []
      },
    };
  },
  computed: {
    isSee () {
      return false;
    },
    pageType () {
      return 'edit';
    },
    ...mapGetters(['lastBreadCrumb', 'refreshPage'])
  },
  watch: {
    $route (to, from) {
      if (to.path === this.filePath && this.refreshPage) {
        this.init();
        this.$store.dispatch('setRefreshPage', false);
      }
    }
  },
  methods: {
    getReferValue (row) {
      let birthday = row.birthday
      let createTime = row.createTime
      let time = Math.abs(new Date(createTime).getTime() - new Date(birthday).getTime())
      const day = Math.floor(time / 1000 / 60 / 60 / 24)
      const hour = Math.floor((time - day * 24 * 60 * 60 * 1000) / 1000 / 60 / 60) + 1
      time = 24 * day + hour
      time = time > 720 ? 720 : time
      const index = this.jaundiceData.findIndex(([x, y], index) => time <= x, 0)
      const [x1, y1] = this.jaundiceData[index - 1]
      const [x2, y2] = this.jaundiceData[index]
      const temp = cal.accMul(cal.accDiv(cal.accSub(y2, y1), cal.accSub(x2, x1)), cal.accSub(time, x2))
      row.referValue = Number(cal.accAdd(temp, y2).toFixed(2))
      row.birthdayTime = `${day}天${hour}小时`
      row.tbState = cal.accSub(row.referValue, row.tbJaundiceValue) >= 0 ? '正常' : '异常'
      return row
    },
    clear () {
      this.form = {
        cardiacDevices: [],
        jaundiceDevices: []
      };
    },
    init () {
      this.clear();
      this.getById(this.$route.query.tbId);
    },
    initForm (form) {
      if (form.tbAreaId) {
        form.areaData = [form.tbProvinceId, form.tbCityId, form.tbAreaId]
      }
      if (form?.jaundiceDevices?.length > 0) {
        form.jaundiceDevices = form.jaundiceDevices.map(i => {
          return this.getReferValue(i)
        })
      }
      return form;
    },
    getById (id) {
      this.$http.get(`/${controller}/findCustomerById`, { tbId: id }).then((res) => {
        if (res.code === 1000) {
          this.form = this.initForm(res.data);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    returnList () {
      this.closeTab(
        this.filePath,
        parentName,
        this.fileName,
        ['', ...suffixList].join('/'),
        true
      );
    },
    beforeSave (form) {
      return form;
    },
    save () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          form = this.beforeSave(form);
          this.$http.post(`/${controller}/save`, form).then((res) => {
            if (res.code === 1000) {
              this.returnList();
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    }
  },
  created () {
    this.init();
  },
  mounted () { },
  destroyed () { },
  components: {
    baseInfo,
    maintainInfo,
    orderInfo,
    cardiacInfo,
    logInfo,
    jaundiceInfo
  }
};
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    overflow-x: auto;
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-motherDetail {
      padding-top: 4px;
      .addButton {
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 24px;
        margin-left: 170px;
        width: 645px;
        height: 32px;
        border-radius: 2px;
        border: 1px dashed rgba(0, 0, 0, 0.25);
        font-size: 14px;
        background-color: rgba(245, 245, 245, 1);
        color: #999999;
        display: block;
        &:focus {
          outline: none;
        }
        .margin11 {
          margin-right: 11px;
        }
      }
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .ui-box-tableList-img {
          width: 80px;
          height: 80px;
          background: #d8d8d8;
          display: inline-block;
          vertical-align: top;
        }
        &.inline {
          display: inline-block;
          vertical-align: top;
        }
        &.height-auto {
          ::v-deep .el-form-item__content {
            height: auto;
          }
        }

        ::v-deep .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          padding-left: 20px;
        }
        ::v-deep .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-motherDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .width761 {
          width: 761px;
        }

        .ui-box-from-motherDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          ::v-deep .el-icon-time,
          ::v-deep .el-icon-arrow-down,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-icon-date,
          ::v-deep .el-range__icon,
          ::v-deep .el-range-separator,
          ::v-deep .el-range-input,
          ::v-deep .el-range__close-icon,
          ::v-deep .el-icon-circle-close,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          ::v-deep .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          ::v-deep .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          ::v-deep .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          ::v-deep .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          ::v-deep .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-motherDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
        .ui-box-from-motherDetail-img {
          cursor: pointer;
          line-height: 32px;
          margin-right: 8px;
        }
        .ui-box-from-motherDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: middle;
          height: 100%;
          margin-right: 30px;
        }
      }
    }
    .ui-box-motherDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-motherDetail {
      padding-top: 4px;
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-motherDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .el-form-item__error {
          padding-left: 20px;
        }
        .ui-box-from-motherDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          .el-icon-time,
          .el-icon-arrow-down,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-icon-date,
          .el-range__icon,
          .el-range-separator,
          .el-range-input,
          .el-icon-circle-close,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-motherDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .ui-box-motherDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>