<template>
  <div class="ui-box-item">
    <titleComponents :show.sync="show" name="操作信息"></titleComponents>
    <div class="itemInfo-detail" v-show="show">
      <table class="ui-box-table">
        <tr>
          <th style="width:5%">序号</th>
          <th style="width:8%">操作人</th>
          <th style="width:8%">类型</th>
          <th style="width:10%">操作时间</th>
          <th style="width:25%">操作记录</th>
          <th>备注</th>
        </tr>
        <tr v-if="data.maintainLogs?.length === 0">
          <td colspan="6">暂无</td>
        </tr>
        <template v-else>
          <tr v-for="(item,index) in data.maintainLogs" :key="item.tbId">
            <td>{{index+1}}</td>
            <td>{{item.tbServiceUserName}}</td>
            <td>{{item.tbType ===1 ?'电话沟通':'微信沟通'}}</td>
            <td>{{item.createTime}}</td>
            <td>{{item.tbTag}}</td>
            <td>{{item.tbRemark}}</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import titleComponents from '@/components/mother/title'
export default {
  name: 'cardiacInfo',
  props: {
    data: {
      type: Object,
      default: () => { }
    },
  },
  data () {
    return {
      show: true
    }
  },
  created () {

  },
  methods: {

  },
  components: {
    titleComponents
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  margin: 20px 24px 0;
  background: rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  width: calc(100% - 48px);
  .itemInfo-detail {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .ui-box-table {
      table-layout: fixed;
      text-align: center;
      width: 100%;
      border: 1px solid #d8d8d8;
      border-radius: 6px;
      border-collapse: collapse;
      border-style: hidden;
      box-shadow: 0 0 0 1px #e0e0e0;
      overflow: hidden;
      tr {
        border: 1px solid #e0e0e0;
        height: 54px;
        line-height: 54px;
        th {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 700;
          color: #000000;
          text-align: center;
          border: 1px solid #e0e0e0;
        }
        td {
          background: #ffffff;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          border: 1px solid #e0e0e0;
        }
      }
    }
  }
}
</style>
     