<template>
  <el-container class="ui-container-left">
    <el-aside class="ui-left" :class="isCollapse ? 'asideActive' : 'asideClose'">
      <div class="ui-menu">
        <el-menu :collapse="isCollapse" @select="handleOpen" ref="menu" :default-active="defaultActive" unique-opened router background-color="#2E4055" text-color="#FFFFFF" active-text-color="#3F9EFF">
          <menuTree :leftMenuList='menuLeftTreeData'></menuTree>
        </el-menu>
      </div>
    </el-aside>
    <el-container>
      <el-main class="ui-main">
        <breadCrumb></breadCrumb>
        <div class="ui-tabs-view">
          <tabsNav></tabsNav>
          <div class="ui-view">
            <keep-alive :include="include">
              <router-view></router-view>
            </keep-alive>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import menuTree from '@/components/layout/menu'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      include: [],
    }
  },
  watch: {
  },
  computed: {
    menuLeftTreeData () {
      return this.menuLeftTree.sort((a, b) => a.tbSort - b.tbSort)
    },
    defaultActive () {
      const list = this.$route.path.split('/')
      if (list.length < 3) {
        return this.$route.path
      } else {
        list.shift()
        if (this.menuIsPop(list, this.menuLeftTreeData)) {
          list.pop()
        }
        list.unshift('')
        return list.join('/')
      }
    },
    ...mapGetters(['sidebar', 'menuLeftTree']),
    isCollapse () {
      return !this.sidebar
    }
  },
  components: {
    menuTree
  },
  methods: {
    breadList (key) {
      const pathList = key.split('/')
      pathList.shift()
      if (this.menuIsPop(pathList, this.menuLeftTreeData)) {
        pathList.pop()
      }
      return this.recursive(pathList, this.menuLeftTree)
    },
    recursive (pathList, routeList, breadList = []) {
      if (pathList.length === 0) {
        return breadList
      }
      const path = pathList.shift()
      const temp = routeList.find((i) => i.tbPath.split('/').pop() === path)
      breadList.push(temp)
      return this.recursive(pathList, temp.children, breadList)
    },
    showIndex () { },
    _getMenuObject () { },
    handleOpen (key) {
      var route = this.breadList(key).pop()
      route.path = route.tbPath
      route.name = route.tbName
      var tabsNavList = JSON.parse(window.sessionStorage.getItem('tabsNavList')) || []
      if (!tabsNavList.find(({ path }) => path === route.path)) {
        tabsNavList.push(route)
      }
      this.resetSetItem('tabsNavList', JSON.stringify(tabsNavList))
    }
  },
  created () {
    this.handleOpen(this.$route.fullPath)
    this.include = JSON.parse(window.sessionStorage.getItem('tabsNavList')).map(
      (i) => i.path.replaceAll('/', '')
    )
    window.addEventListener('tabsNavList', () => {
      this.include = JSON.parse(
        window.sessionStorage.getItem('tabsNavList')
      ).map((i) => i.path.replaceAll('/', ''))
    })
  },
  destroyed () {
    window.removeEventListener(
      'tabsNavList',
      () => {
        this.include = JSON.parse(
          window.sessionStorage.getItem('tabsNavList')
        ).map((i) => i.path.replaceAll('/', ''))
      },
      true
    )
  }
}
</script>
<style lang="css" scoped>
>>> #left-menu .el-submenu .el-submenu__title {
  background-color: #2e4055 !important;
  color: red;
}
>>> #left-menu .el-submenu .el-submenu__title * {
  display: block !important;
  overflow: hidden !important;
}
>>> #left-menu .el-menu-item.is-active {
  color: #3f9eff !important;
}
>>> #left-menu .el-menu--inline .el-menu-item {
  background-color: #1f2d3d !important;
}
.el-menu--collapse >>> .el-submenu__icon-arrow {
  display: none;
}
</style>
<style lang="scss" scoped>
.ui-container-left {
  width: 100%;
  height: calc(100% - #{$header-height}) !important;
  margin-top: $header-height;
  background: rgb(245, 245, 245);
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    min-width: calc(#{$left-width} - 1px) !important;
  }
  .ui-left::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }
  .ui-left {
    padding: 0;
    width: $left-width !important;
    height: 100%;
    background-color: #2e4055;
    overflow-y: auto;
    overflow-x: hidden;
    &.asideActive {
      line-height: 8.8rem;
      width: 65px !important;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      ::v-deep .el-menu--collapse {
        width: 65px !important;
      }
    }
    &.asideClose {
      line-height: 8.8rem;
      width: $left-width !important;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
    }
    .ui-label {
      color: #fff;
      text-align: left;
      padding-left: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .ui-menu::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
    .ui-menu {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding-right: 20px;
      // // height: calc(100% - 126px);
      // overflow: auto;
      // overflow-y: scroll;
      // scrollbar-color: transparent transparent;
      // scrollbar-track-color: transparent;
      // -ms-scrollbar-track-color: transparent;
      // -webkit-overflow-scrolling: touch;
      ::v-deep .el-menu-item.is-active {
        color: #3f9eff !important;
      }
      ::v-deep .el-submenu {
        ::v-deep .el-submenu__title {
          background-color: #2e4055 !important;
          ::v-deep span {
            display: block !important;
            overflow: hidden !important;
          }
        }
      }
      ::v-deep .el-menu--inline {
        ::v-deep .el-menu-item {
          background-color: #1f2d3d !important;
        }
      }
      ::v-deep .el-menu {
        height: calc(100% - 126px);
        border-right: none;
        ::v-deep .el-menu-item {
          font-size: 12px;
        }
        ::v-deep .el-submenu {
          text-align: left;
          ::v-deep .el-submenu__title {
            background-color: #2e4055 !important;
            ::v-deep span {
              display: block !important;
              overflow: hidden !important;
            }
          }
        }
      }
    }
  }
  .ui-main {
    overflow: hidden;
    padding: 0;
    .ui-tabs-view {
      width: 100%;
      height: calc(100% - 50px);
      .ui-view {
        width: 100%;
        height: calc(100% - 46px);
        overflow-y: scroll;
        overflow-y: overlay;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
    }
  }
}
</style>
