const recursive = (pathList, routeList) => {
    if (pathList.length === 0) {
        return false
    }
    const path = pathList.shift()
    const temp = routeList.find(i => i.tbPath.split('/').pop() === path)
    if (!temp) {
        return true
    }
    return recursive(pathList, temp.children || [])
}
const getDataType = (data) => {
    const temp = Object.prototype.toString.call(data)
    const type = temp.match(/\b\w+\b/g)
    return (type.length < 2) ? 'Undefined' : type[1]
}
export default (Vue) => {
    Vue.prototype.getPromise = function (url, type, params) {
        return new Promise((resolve, reject) => {
            this.$http[type](url, params)
                .then(res => {
                    if (res.code === 1000) {
                        resolve(res)
                    } else {
                        reject(res)
                    }
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
    Vue.prototype.setTreeData = function (tree, children) {
        return tree.map(i => {
            if (i[children].length === 0) {
                i[children] = undefined
            } else {
                i[children] = this.setTreeData(i[children], children)
            }
            return i
        })
    }
    Vue.prototype.setUrlOss = function (url) {
        if (!url) return ''
        if (!url.substr) {
            debugger
        }
        if (url.substr(0, 7) === 'http://') {
            return url
        }
        if (url.substr(0, 8) === 'https://') {
            return url
        }
        if (url.includes('oss')) {
            if (url.substr(0, 10) === '/pro/https') {
                return url.substr(5, url.length)
            }
            return url
        }
        if (url.includes('prod')) {
            return url.substr(0, 1) === '/' ? 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com' + url : 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/' + url
        }
        return url.includes('http://hylyljk.com/') ? url : url.substr(0, 1) === '/' ? 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com' + url : 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/' + url
    }
    Vue.prototype.getUrl = function (url) {
        if (!url) return ''
        if (url.includes('oss')) {
            if (url.substr(0, 10) === '/pro/https') {
                return url.substr(5, url.length)
            }
            return url
        }
        if (url.includes('prod')) {
            return url.substr(0, 1) === '/' ? 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com' + url : 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/' + url
        }
        return url.includes('http://hylyljk.com/') ? url : url.substr(0, 1) === '/' ? 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com' + url : 'https://hyl-ymm.oss-cn-beijing.aliyuncs.com/' + url
    }
    Vue.prototype.errorImg = function (event) {
        const img = event.srcElement
        event.target.style.objectFit = 'contain'
        img.src = require('@/assets/img/icon/404.png')
        img.onerror = null
    }
    Vue.prototype.menuIsPop = function (pathList, menuData) {
        const pathLists = JSON.parse(JSON.stringify(pathList))
        return recursive(pathLists, menuData)
    }
    Vue.prototype.lodingImg = function (event) {
        const img = event.srcElement
        event.target.style.objectFit = 'contain'
        img.src = require('@/assets/img/loading.gif')
        img.onerror = null
    }
    Vue.prototype.resetSetItem = function (key, newVal) {
        if (key === 'tabsNavList') {
            var newStorageEvent = document.createEvent('StorageEvent')
            const storage = {
                setItem: function (key, val) {
                    sessionStorage.setItem(key, val)
                    newStorageEvent.initStorageEvent('tabsNavList', false, false, key, null, val, null, null)
                    window.dispatchEvent(newStorageEvent)
                }
            }
            return storage.setItem(key, newVal)
        }
    }
    Vue.prototype.openTab = function (title, lastPathName, fullPath, data = {}) {
        const lastBreadCrumb = JSON.parse(JSON.stringify(this.lastBreadCrumb))
        lastBreadCrumb[lastPathName] = title
        this.$store.dispatch('setLastBreadCrumb', lastBreadCrumb)
        var tabsNavList = JSON.parse(window.sessionStorage.getItem('tabsNavList')) || []
        if (!tabsNavList.find(({ path }) => path === fullPath)) {
            tabsNavList.push({ name: title, path: fullPath, query: data })
        } else {
            tabsNavList.map(i => {
                if (i.path === fullPath) {
                    if (this.isObjectChanged(data, i.query)) {
                        i.query = data
                        this.$store.dispatch('setRefreshPage', true)
                    }

                    i.name = title
                }
                return i
            })
        }
        this.resetSetItem('tabsNavList', JSON.stringify(tabsNavList))
        this.$router.push({ path: fullPath, query: data }).catch(e => { })
    }
    Vue.prototype.closeTab = function (closeFullPath, title, lastPathName, fullPath, refreshPage = false) {
        var tabsNavList = JSON.parse(window.sessionStorage.getItem('tabsNavList')) || []
        if (title) {
            tabsNavList = tabsNavList.filter(({ path }) => path !== closeFullPath)
            this.resetSetItem('tabsNavList', JSON.stringify(tabsNavList))
            refreshPage && this.$store.dispatch('setRefreshPage', refreshPage)
            this.openTab(title, lastPathName, fullPath, {})
        } else {
            if (tabsNavList.length > 1) {
                let query = {}
                let route = tabsNavList[0].path
                tabsNavList.filter(({ path }, i) => {
                    if (path === closeFullPath) {
                        if (i !== 0) {
                            route = tabsNavList[i - 1].path
                            query = tabsNavList[i - 1].query
                        }
                        if (i < tabsNavList.length - 1) {
                            route = tabsNavList[i + 1].path
                            query = tabsNavList[i + 1].query
                        }
                    }
                    return path !== closeFullPath
                })
                tabsNavList = tabsNavList.filter(({ path }) => path !== closeFullPath)
                this.resetSetItem('tabsNavList', JSON.stringify(tabsNavList))
                this.$store.dispatch('setRefreshPage', refreshPage)
                this.$router.push({ path: route, query: query }).catch(e => { })
            }
        }
    }

    Vue.prototype.calcHeight = function (value, wappered = true) {
        const res = window.innerHeight - value
        if (wappered) {
            const wapper = window.document.getElementsByClassName('el-table__body-wrapper')
            const header = window.document.getElementsByClassName('el-table__header-wrapper')
            if (wapper[0] && wapper[0].style) {
                setTimeout(() => {
                    wapper[0].style.height = (res - header[0].clientHeight) + 'px'
                }, 100)
            }
        }
        return res
    }
    Vue.prototype.destroyResizeListen = function (height) {
        window.removeEventListener('resize', () => {
            this.tableHeight = this.calcHeight(height)
        }, true)
    }
    Vue.prototype.addResizeListen = function (height) {
        window.addEventListener('resize', () => {
            this.tableHeight = this.calcHeight(height)
        })
        this.tableHeight = this.calcHeight(height)
    }
    Vue.prototype.isObjectChanged = function (source, comparison) {
        const iterable = (data) => ['Object', 'Array'].includes(getDataType(data))
        if (!iterable(source)) {
            throw new Error(`source should be a Object or Array , but got ${getDataType(source)}`)
        }

        if (getDataType(source) !== getDataType(comparison)) {
            return true
        }

        const sourceKeys = Object.keys(source)

        const comparisonKeys = Object.keys({ ...source, ...comparison })

        if (sourceKeys.length !== comparisonKeys.length) {
            return true
        }
        return comparisonKeys.some(key => {
            if (iterable(source[key])) {
                return this.isObjectChanged(source[key], comparison[key])
            } else {
                return source[key] !== comparison[key]
            }
        })
    }
}


