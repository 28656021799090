<template>
  <div class="ui-content">
    <div class="ui-box">
      <el-form ref="form" v-loading="loading" class="ui-box-from-roleDetail" label-position="right" :rules="rules" :model="form" label-width="150px">

        <el-form-item label="角色名称" prop="tbName">
          <el-input class="ui-box-from-roleDetail-input width645" placeholder="请输入角色名称" v-model="form.tbName" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="角色描述" prop="tbDescription">
          <el-input type="textarea" class="width645" :rows="5" placeholder="请输入角色描述" v-model="form.tbDescription" :disabled="pageType==='see'"></el-input>
        </el-form-item>
        <el-form-item label="菜单权限">
          <el-tree class="row-tree" :check-strictly="true" :accordion="true" :default-checked-keys="checkedMenuTree" :disabled="pageType==='see'" :default-expand-all="true" ref="menuTree" :data="menuList" show-checkbox node-key="tbId" :props="menuDefaultProps">
          </el-tree>
        </el-form-item>
      </el-form>
      <div class="ui-box-roleDetail-button">
        <el-button class="return" @click="returnList" v-has="pathCode+':return'">返回列表</el-button>
        <el-button type="primary" class="submit" v-has="pathCode+':save'" v-show="pageType!=='see'" @click="save" v-focus @keyup.enter.native="save">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
const files = require.context('/', false, /\.vue$/);
const suffixList = files.id
  .split(' ')[0]
  .split('/')
  .filter((a, b) => b > 2);
const fileName = 'roleDetail';
const controller = 'role';
const parentName = '角色管理';
import cascaderTree from '@/components/cascaderTree/cascaderTree.vue';
import { mapGetters } from 'vuex';
export default {
  inject: ['reload'],
  name: `${suffixList.join('')}${fileName}`,
  data () {
    const _this = this;
    return {
      pathCode: `${suffixList.join('')}${fileName}`,
      password: '',
      loading: false,
      defaultMsg: '',
      config: {
        initialFrameWidth: '100%',
        initialFrameHeight: 350
      },
      filePath: ['', ...suffixList, fileName].join('/'),
      fileName: fileName,
      rules: {
        tbAccount: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        tbPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        tbName: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ],
        tbPassword: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
      },
      areaProps: {
        multiple: true,
        value: 'id',
        label: 'areaName',
        children: 'children'
      },
      form: {

      },
      checkedMenuTree: [],
      menuList: [],
      menuDefaultProps: {
        children: 'children',
        label: 'tbName'
      },
    };
  },
  computed: {
    isSee () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['查看角色'].includes(this.lastBreadCrumb[this.fileName])) {
          return true;
        }
      }
      return false;
    },
    pageType () {
      if (Object.keys(this.lastBreadCrumb.length > 0)) {
        if (['新增角色'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'add';
        } else if (['修改角色'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'edit';
        } else if (['查看角色'].includes(this.lastBreadCrumb[this.fileName])) {
          return 'see';
        }
      }
      return 'add';
    },
    ...mapGetters(['lastBreadCrumb', 'refreshPage', 'areaTree'])
  },
  watch: {
    $route (to, from) {
      if (to.path === this.filePath && this.refreshPage) {
        this.init();
        this.$store.dispatch('setRefreshPage', false);
      }
    }
  },
  methods: {
    getMenuList () {
      this.$http.get('/menu/findList', {})
        .then((res) => {
          this.menuList = res.data
        })
        .catch((res) => { })
    },
    clear () {
      this.form = {

      };
      this.checkedMenuTree = []
      this.$refs.menuTree && this.$refs.menuTree.setCheckedKeys([])
    },
    init () {
      this.type = this.$route.query.type;
      if (this.pageType !== 'add') {
        this.getById(this.$route.query.tbId);
      } else {
        this.clear();
      }
    },
    initForm (form) {
      this.checkedMenuTree = form.menuIds
      return form;
    },
    getById (id) {
      this.$http.get(`/${controller}/get`, { tbId: id }).then((res) => {
        if (res.code === 1000) {
          this.form = this.initForm(res.data);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    returnList () {
      this.closeTab(
        this.filePath,
        parentName,
        this.fileName,
        ['', ...suffixList].join('/'),
        true
      );
    },
    beforeSave (form) {
      form.menuIds = this.$refs.menuTree.getCheckedKeys()
      return form;
    },
    setPhoto (data, filed) {
      if (data[filed]) {
        data[`${filed}Temp`] = data[filed] = data[filed].split(',').map((i) => {
          return {
            name: '',
            url: this.setUrlOss(i)
          };
        });
      } else {
        data[`${filed}Temp`] = data[filed] = [];
      }
    },
    save () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let form = JSON.parse(JSON.stringify(this.form));
          form = this.beforeSave(form);
          this.loading = true
          this.$http.post(`/${controller}/save`, form).then((res) => {
            this.loading = false
            if (res.code === 1000) {
              this.$store.dispatch("getMenuListByUser")
              this.reload();
              this.returnList();
            } else {
              this.$message.error(res.message);
            }
          })
        } else {
          return false;
        }
      });
    }
  },
  created () {
    if (this.areaTree.length === 0) {
      this.$store.dispatch('getAreaTree', this);
    }
    this.init();
    this.getMenuList()
  },
  mounted () { },
  destroyed () { },
  components: {
    cascaderTree
  }
};
</script>

<style lang="scss" scoped>
.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    overflow-x: auto;
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-roleDetail {
      padding-top: 29px;
      .addButton {
        cursor: pointer;
        margin-top: 5px;
        margin-bottom: 24px;
        margin-left: 170px;
        width: 645px;
        height: 32px;
        border-radius: 2px;
        border: 1px dashed rgba(0, 0, 0, 0.25);
        font-size: 14px;
        background-color: rgba(245, 245, 245, 1);
        color: #999999;
        display: block;
        &:focus {
          outline: none;
        }
        .margin11 {
          margin-right: 11px;
        }
      }
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .ui-box-tableList-img {
          width: 80px;
          height: 80px;
          background: #d8d8d8;
          display: inline-block;
          vertical-align: top;
        }
        &.inline {
          display: inline-block;
          vertical-align: top;
        }
        &.height-auto {
          ::v-deep .el-form-item__content {
            height: auto;
          }
        }

        ::v-deep .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        ::v-deep .el-form-item__error {
          padding-left: 20px;
        }
        ::v-deep .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-roleDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .width761 {
          width: 761px;
        }

        .ui-box-from-roleDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          ::v-deep .el-icon-time,
          ::v-deep .el-icon-arrow-down,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-icon-date,
          ::v-deep .el-range__icon,
          ::v-deep .el-range-separator,
          ::v-deep .el-range-input,
          ::v-deep .el-range__close-icon,
          ::v-deep .el-icon-circle-close,
          ::v-deep .el-icon-arrow-up,
          ::v-deep .el-icon-date,
          ::v-deep .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          ::v-deep .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          ::v-deep .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          ::v-deep .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          ::v-deep .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          ::v-deep .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-roleDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
        .ui-box-from-roleDetail-img {
          cursor: pointer;
          line-height: 32px;
          margin-right: 8px;
        }
        .ui-box-from-roleDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: middle;
          height: 100%;
          margin-right: 30px;
        }
      }
    }
    .ui-box-roleDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.ui-content {
  width: 100%;
  height: 100%;
  .ui-box {
    margin: 0 24px;
    padding-bottom: 24px;
    width: calc(100% - 48px);
    min-height: calc(100% - 24px);
    height: auto;
    background: #ffffff;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .ui-box-from-roleDetail {
      padding-top: 29px;
      ::v-deep .el-form-item {
        margin-bottom: 24px;
        .el-form-item__label {
          height: 32px;
          line-height: 32px;
          padding: 0;
          margin: 0;
        }
        .el-form-item__content {
          height: 100%;
          padding-left: 20px;
          line-height: 32px;
        }
        .ui-box-from-roleDetail-text {
          cursor: pointer;
          line-height: 32px;
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: 48px;
        }
        .width645 {
          width: 645px;
        }
        .margin40 {
          margin-right: 40px;
        }
        .el-form-item__error {
          padding-left: 20px;
        }
        .ui-box-from-roleDetail-input {
          width: 275px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &.width645 {
            width: 645px;
          }
          .el-icon-time,
          .el-icon-arrow-down,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-icon-date,
          .el-range__icon,
          .el-range-separator,
          .el-range-input,
          .el-icon-circle-close,
          .el-icon-arrow-up,
          .el-icon-date,
          .el-input__inner {
            vertical-align: top;
            height: 30px;
            line-height: 30px;
            border: none;
          }
          .el-input__inner::-webkit-outer-spin-button,
          // eslint-disable-next-line no-irregular-whitespace
          .el-input__inner::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
          .el-input__inner[type="number"] {
            -moz-appearance: textfield;
          }
          .el-input-group__append {
            height: 30px;
            border: none;
            &::-moz-placeholder,
            &::-webkit-input-placeholder,
            &::-ms-input-placeholder {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #cccccc;
            }
          }
          .el-input-group__append {
            padding: 0 15px;
          }
        }
        .ui-box-from-roleDetail-button {
          margin-left: 20px;
          vertical-align: top;
          padding: 8px 32px;
          border-radius: 3px;
          &:focus {
            outline: none;
          }
        }
      }
    }
    .ui-box-roleDetail-button {
      margin-left: 217px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      .return {
        padding: 6px 25px;
        margin-right: 40px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
      .submit {
        margin-left: 0;
        padding: 6px 25px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        line-height: 22px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>