<template>
  <el-dialog title="备注订单" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="600px" :before-close="dialogBeforeClose">
    <div class="ui-box-dialog-remark">操作备注</div>
    <el-input class="ui-box-dialog-textarea" type="textarea" :rows="4" v-model="data.tbRemarks">
    </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
      <el-button class="ui-box-submit" type="primary" @click="updateOrderRemarks">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'remarkOrder',
  props: {
    data: Object,
    tbId: String,
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
    }
  },
  computed: {

  },
  methods: {
    updateOrderRemarks () {
      this.$http.post('/order/updateOrderRemarks', { 'tbId': this.data.tbId, orderType: this.$route.query.orderType, 'tbRemarks': this.data.tbRemarks })
        .then(res => {
          if (res.code === 1000) {
            this.dialogBeforeClose()
            this.$message({ type: 'success', message: '订单备注成功' })
          } else {
            this.$message.error(res.message)
          }
        })
    },
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .ui-box-dialog-remark {
    margin-left: 39px;
    margin-right: 20px;
    width: 56px;
    height: 22px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
  }
  .ui-box-dialog-textarea {
    display: inline-block;
    width: 407px;
    vertical-align: top;
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
</style>