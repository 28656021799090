<template>
  <el-dialog title="添加商品" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="674px" :before-close="dialogBeforeClose">
    <el-form ref="form" class="ui-box-dialog-from" label-position="right" :model="form" label-width="130px">
      <el-form-item label="商品货号" class="ui-box-dialog-label">
        <el-input class="ui-box-dialog-from-input" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="商品名称" class="ui-box-dialog-label">
        <span>新生婴儿宽口径玻璃奶瓶网红小Q瓶</span>
      </el-form-item>
      <el-form-item label="商品图片" class="ui-box-dialog-label">
        <el-input class="ui-box-dialog-from-input" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="规格" class="ui-box-dialog-label">
        <el-select class="ui-box-dialog-from-input width188" v-model="form.name" placeholder="">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="商品价格" class="ui-box-dialog-label">
        <span>原价：￥50 折扣价：￥45</span>
      </el-form-item>
      <el-form-item label="数量" class="ui-box-dialog-label">
        <el-input class="ui-box-dialog-from-input" v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
      <el-button class="ui-box-submit" type="primary" @click="dialogVisible = false">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'addGoods',
  props: {
    updateGoodDialogVisible: {
      type: Boolean
    },
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      form: {},
      textarea: '',
      options: []
    }
  },
  computed: {

  },
  methods: {
    handleSelectionChange (val) {
      this.selectRow = val
    },
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
      this.$emit('update:updateGoodDialogVisible', true)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .ui-box-dialog-from {
    .width439 {
      width: 439px;
    }
    .ui-box-dialog-label {
      margin-bottom: 24px;
      ::v-deep .el-form-item__label {
        margin-bottom: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
      }
    }
    .ui-box-dialog-from-input {
      &.width188 {
        margin-right: 2px;
        width: 188px;
      }
      width: 188px;
      height: 36px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      ::v-deep .el-input__inner,
      ::v-deep .el-input__icon {
        height: 34px;
        line-height: 34px;
        border: none;
        vertical-align: top;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
</style>