<template>
  <div class="ui-order-status-box">
    <img class="ui-order-status-img" :src="statusImg" />
    <div class="ui-order-status-text">{{statusLabel}}：{{statusText}}</div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'statusInfo',
  props: {
    statusLabel: String,
    statusText: String
  },
  data() {
    return {
      statusImg: require('../../assets/img/order/warn.png')
    }
  },
  computed: {

  },
  methods: {
  },
  components: {

  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.ui-order-status-box {
  display: inline-block;
  margin: 24px 24px 40px;
  width: calc(100% - 48px);
  height: 80px;
  background: #f2f6fc;
  .ui-order-status-img {
    display: inline-block;
    margin: 30px 0 0 24px;
    width: 20px;
    height: 20px;
    vertical-align: top;
  }
  .ui-order-status-text {
    vertical-align: top;
    display: inline-block;
    margin: 26px 0 0 4px;
    width: 700px;
    height: 28px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e86969;
    line-height: 28px;
  }
}
</style>
     