import http from '@/http/index.js'
import OSS from 'ali-oss'
import { Message } from 'element-ui'
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    menuList: [],
    menuTree: [],
    menuLeftTree: [],
    ossClient: null,
    stsToken: {},
    areaTree: [],
    user: {},
    refreshPage: false,
    lastBreadCrumb: {},
    sidebar: true
  },
  mutations: {
    SET_MENU_LIST (state, val) {
      state.menuList = val
    },
    SET_MENU_LEFT_TREE (state, val) {
      state.menuLeftTree = val
    },
    SET_MENU_TREE (state, val) {
      state.menuTree = val
    },
    SET_OSS_CLIENT (state, val) {
      state.ossClient = val
    },
    SET_STS_TOKEN (state, val) {
      state.stsToken = val
    },
    SET_AREA_TREE (state, val) {
      state.areaTree = val
    },
    SET_REFRESH_PAGE (state, val) {
      state.refreshPage = val
    },
    SET_LAST_BREAD_CRUMB (state, val) {
      state.lastBreadCrumb = val
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar = !state.sidebar
    },
    SET_USER (state, val) {
      state.user = val
    }
  },
  actions: {
    getMenuListByUser ({ commit, state }) {
      return new Promise((resolve, reject) => {
        http.post('/menu/getMenuListByUser', {})
          .then((res) => {
            if (res.code === 1000) {
              commit('SET_MENU_LIST', res.data.list)
              commit('SET_MENU_TREE', res.data.tree)
              commit('SET_MENU_LEFT_TREE', res.data.leftTree)
              resolve(res.data)
            } else if (res.code === 401) {
              router.push('/login')
              reject('error')
              return false
            } else {
              Message({
                message: '错误信息：' + res.message,
                type: 'error',
                duration: 3 * 1000
              })
              resolve('error')
              return false
            }
          })
          .catch((error) => {
            resolve(error)
          })
      })
    },
    setOssClient ({ commit, state, dispatch }, vue) {
      return new Promise((resolve, reject) => {
        const ossClient = new OSS({
          region: 'oss-cn-beijing',
          bucket: 'hyl-ymm',
          accessKeyId: state.stsToken.accessKeyId,
          accessKeySecret: state.stsToken.accessKeySecret,
          stsToken: state.stsToken.securityToken,
          refreshSTSToken: async () => {
            // 向您搭建的STS服务获取临时访问凭证。
            const stsToken = await dispatch('setStsToken')
            return {
              accessKeyId: stsToken.accessKeyId,
              accessKeySecret: stsToken.accessKeySecret,
              stsToken: stsToken.securityToken
            }
          },
          refreshSTSTokenInterval: 3500000,
          secure: true
        })
        commit('SET_OSS_CLIENT', ossClient)
        resolve(ossClient)
      })
    },
    setStsToken ({ commit, state }, vue) {
      return new Promise((resolve, reject) => {
        const expiration = state.stsToken?.expiration
        if (expiration && new Date(expiration).getTime() - new Date().getTime() >= 60 * 10 * 1000) {
          resolve(state.stsToken)
        } else {
          http.post(`/getStsToken`, {})
            .then(res => {
              if (res.code === 1000) {
                commit('SET_STS_TOKEN', res.data)
                resolve(res.data)
              } else {
                reject(res)
              }
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    },
    setRefreshPage ({ commit }, val) {
      commit('SET_REFRESH_PAGE', val)
    },
    setLastBreadCrumb ({ commit }, val) {
      window.sessionStorage.setItem('lastBreadCrumb', JSON.stringify(val))
      commit('SET_LAST_BREAD_CRUMB', val)
    },
    toggleSideBars ({ commit }) {
      commit('TOGGLE_SIDEBAR')
    },
    getAreaTree ({ commit }, vue) {
      return new Promise((resolve, reject) => {
        http.post('/area/getTree', {})
          .then(res => {
            if (res.code === 1000) {
              let list = [{
                areaCode: '全选',
                areaName: '全选',
                areaParentId: '0',
                leaf: false,
                id: 100000,
                children: res.data
              }]
              list = vue.setTreeData(list, 'children')
              commit('SET_AREA_TREE', list)
              resolve(list)
            } else {
              reject(res)
            }
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getUserInfo ({
      commit,
      state
    }) {
      return new Promise((resolve, reject) => {
        http.post('/users/getLoginedUserInfo', {})
          .then(res => {
            commit('SET_USER', res.data)
            resolve(res.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  modules: {},
  getters: {
    menuLeftTree: state => state.menuLeftTree,
    ossClient: state => state.ossClient,
    stsToken: state => state.stsToken,
    areaTree: state => state.areaTree,
    refreshPage: state => state.refreshPage,
    sidebar: state => state.sidebar,
    lastBreadCrumb: state => {
      if (Reflect.ownKeys(state.lastBreadCrumb).length < 2) {
        return JSON.parse(window.sessionStorage.getItem('lastBreadCrumb')) || {}
      } else {
        return state.lastBreadCrumb
      }
    }
  }
})
