<template>
  <div class="ui-box-item">
    <titleComponents :show.sync="show" name="维护信息"></titleComponents>
    <div class="itemInfo-detail" v-show="show">
      <div class="item">
        <div class="text">选择</div>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="首次沟通"></el-checkbox>
          <el-checkbox label="黄疸意向"></el-checkbox>
          <el-checkbox label="手环"></el-checkbox>
          <el-checkbox label="月子中心"></el-checkbox>
          <el-checkbox label="产后修复"></el-checkbox>
          <el-checkbox label="添加微信"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="item">
        <div class="text">姓名</div>
        <el-input class="ui-box-from-motherDetail-input marginRight50 width30" v-model="data.username"></el-input>
        <div class="text width42">维护</div>
        <el-select class="ui-box-from-motherDetail-input marginRight50 width30" v-model="data.tbMaintainStatus" placeholder="请选择">
          <el-option v-for="item in maintainStatusList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <div class="text width56">微信号</div>
        <el-input class="ui-box-from-motherDetail-input width30" v-model="data.tbWxId" placeholder="请输入微信号"></el-input>
      </div>
      <div class="item">
        <div class="text">阶段</div>
        <el-select class="ui-box-from-motherDetail-input marginRight50 width30" v-model="data.currentGeneration">
          <el-option label="怀孕中" :value="1"></el-option>
          <el-option label="已出生" :value="2"></el-option>
        </el-select>
        <template v-if="data.currentGeneration === 1">
          <div class="text width42">预产期</div>
          <el-date-picker class="ui-box-from-motherDetail-input marginRight50 width30" v-model="data.prepareProduction" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" @change="prepareProductionChange"></el-date-picker>
        </template>
        <template v-else>
          <div class="text width42">生日</div>
          <el-date-picker class="ui-box-from-motherDetail-input marginRight50 width30" v-model="data.babyBornTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" @change="babyBornTimeChange"></el-date-picker>
        </template>
        <div class="text width56">沟通方式</div>
        <el-select class="ui-box-from-motherDetail-input width30" v-model="tbType">
          <el-option label="电话沟通" :value="1"></el-option>
          <el-option label="微信沟通" :value="2"></el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="text">区域</div>
        <el-cascader class="ui-box-from-motherDetail-input width50 marginRight88" clearable ref="areaRef" popper-class="cascader-tree" v-model="data.areaData" :options="areaOptions" :props="areaProps" @change="cascaderChange"></el-cascader>
        <div class="text">医院</div>
        <el-select class="ui-box-from-motherDetail-input width50" filterable clearable v-model="data.tbHospitalId" placeholder="请选择" @change="hospitalChange">
          <el-option v-for="item in hospitalList" :key="item.tbId" :label="item.tbHospitalName" :value="item.tbId"></el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="text">备注</div>
        <el-input class="width100" type="textarea" :rows="4" v-model="tbRemark" placeholder="请输入备注"></el-input>
      </div>
      <div class="footer">
        <el-button type="primary" @click="addCustomerMaintain" v-has="pathCode+':saveMaintain'">确认提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import titleComponents from '@/components/mother/title'
export default {
  name: 'maintainInfo',
  props: {
    data: {
      type: Object,
      default: () => { }
    },
    pathCode: String
  },
  data () {
    return {
      hospitalList: [],
      tbRemark: '',
      tbType: '',
      maintainStatusList: [
        { id: 0, name: '未维护' },
        { id: 1, name: '未接通' },
        { id: 2, name: '拒绝沟通' },
        { id: 3, name: '维护中' },
        { id: 4, name: '已结束' }
      ],
      checkList: [],
      show: true,
      areaProps: {
        multiple: false,
        value: 'id',
        label: 'areaName',
        children: 'children'
      }
    }
  },
  computed: {
    areaOptions () {
      if (this.areaTree.length > 0) {
        return this.areaTree[0].children
      }
      return []
    },
    ...mapGetters([
      'areaTree'
    ])
  },
  created () {
    if (this.areaTree.length === 0) {
      this.$store.dispatch('getAreaTree', this)
    }
  },
  methods: {
    prepareProductionChange () {
      this.$set(this.data, 'prepareProduction', this.data.prepareProduction)
    },
    babyBornTimeChange () {
      this.$set(this.data, 'babyBornTime', this.data.babyBornTime)
    },
    getHospitalList () {
      this.$http.get('/hospital/selectByArea', { areaId: this.data.tbAreaId })
        .then(res => {
          if (res.code === 1000) {
            this.hospitalList = res.data
          } else {
            this.$message.error(res.message)
          }
        })
    },
    hospitalChange () {
      this.data.tbHospitalName = this.hospitalList.find(({ tbId }) => this.data.tbHospitalId === tbId)?.tbHospitalName || ''
    },
    cascaderChange (val) {
      if (val.length) {
        const labels = this.$refs.areaRef.getCheckedNodes()[0].pathLabels
        this.$set(this.data, 'tbProvinceName', labels[0])
        this.$set(this.data, 'tbCityName', labels[1])
        this.$set(this.data, 'tbAreaName', labels[2])
        this.$set(this.data, 'tbProvinceId', val[0])
        this.$set(this.data, 'tbCityId', val[1])
        this.$set(this.data, 'tbAreaId', val[2])
      } else {
        this.$set(this.data, 'tbProvinceName', '')
        this.$set(this.data, 'tbCityName', '')
        this.$set(this.data, 'tbAreaName', '')
        this.$set(this.data, 'tbProvinceId', '')
        this.$set(this.data, 'tbCityId', '')
        this.$set(this.data, 'tbAreaId', '')
      }
    },
    addCustomerMaintain () {
      if (!this.data.username) {
        this.$message.error('姓名不能为空')
        return ''
      }
      if (this.data.currentGeneration == 1 && !this.data.prepareProduction) {
        this.$message.error('预产期不能为空')
        return ''
      }
      if (this.data.currentGeneration == 2 && !this.data.babyBornTime) {
        this.$message.error('宝宝生日不能为空')
        return ''
      }
      if (!this.tbType) {
        this.$message.error('请选择沟通方式')
        return ''
      }
      if (!this.data.tbHospitalId) {
        this.$message.error('请选择医院')
        return ''
      }
      const data = {
        id: this.data.id,
        tbName: this.data.username,
        hospitalId: this.data.tbHospitalId,
        tbMaintainStatus: this.data.tbMaintainStatus,
        tbWxId: this.data.tbWxId,
        currentGeneration: this.data.currentGeneration,
        babyBornTime: this.data.babyBornTime,
        prepareProduction: this.data.prepareProduction,
        maintainLog: {
          tbType: this.tbType,
          tbUserId: this.data.id,
          tbTag: this.checkList.length ? this.checkList.join(',') : '',
          tbRemark: this.tbRemark
        }
      }
      this.$http.post(`/user/addCustomerMaintain`, data).then((res) => {
        if (res.code === 1000) {
          this.checkList = []
          this.$emit('init');
        } else {
          this.$message.error(res.message);
        }
      });
    }
  },
  watch: {
    'data.tbAreaId': function (val) {
      if (val) {
        this.getHospitalList()
      } else {
        this.hospitalList = []
      }
    }
  },
  components: {
    titleComponents
  }
}
</script>

<style lang="scss" scoped>
.ui-box-item {
  margin: 20px 24px 0;
  background: rgba(0, 0, 0, 0.02);
  box-sizing: border-box;
  width: calc(100% - 48px);
  .itemInfo-detail {
    border-top: 1px solid #e5e5e5;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    .text {
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      margin-right: 20px;
      height: 22px;
      line-height: 22px;
      text-align: right;
      &.width42 {
        width: 42px;
      }
      &.width56 {
        width: 56px;
      }
    }
    .footer {
      margin-top: 10px;
      width: 100%;
      text-align: right;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 0 0 15px 8px;
      box-sizing: border-box;
    }
    .width100 {
      width: calc(100% - 48px);
    }
    .ui-box-from-motherDetail-input {
      width: 275px;
      height: 32px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      &.width635 {
        width: 635px;
      }
      &.marginRight50 {
        margin-right: 50px;
      }
      &.width30 {
        width: calc((100% - 286px) / 3);
      }
      &.width50 {
        width: calc((100% - 184px) / 2);
      }
      &.marginRight88 {
        margin-right: 88px;
      }

      ::v-deep .el-icon-circle-close,
      ::v-deep .el-icon-arrow-down,
      ::v-deep .el-icon-arrow-up,
      ::v-deep .el-icon-date,
      ::v-deep .el-input__inner {
        vertical-align: top;
        height: 30px;
        line-height: 30px;
        border: none;
      }
      ::v-deep .el-input-group__append {
        background: #fff;
        border-radius: 0;
        border: none;
        border-left: 1px solid #dcdfe6;
      }
    }
  }
}
</style>
     