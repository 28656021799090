<template>
  <div class="ui-tabsNav">
    <tabs v-model="activeName" @tab-click="handleClick" closable @tab-remove="removeTab" @contextmenu.prevent.native.stop="openMenu($event)">
      <el-tab-pane v-for="item in tabsNavs" :key="item.path" :label="item.name" :name="item.path" closable> </el-tab-pane>
    </tabs>
    <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
      <li @click="close">关闭</li>
      <li @click="closeOther">关闭其他</li>
      <li @click="closeDirection(0)">关闭到右侧</li>
      <li @click="closeDirection(1)">关闭到左侧</li>
      <li @click="closeAll">全部关闭</li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'tabsNav',
  data () {
    return {
      clickPath: '',
      top: 0,
      left: 0,
      visible: false,
      tabsNavList: []
    }
  },
  watch: {
    visible (value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
        document.body.addEventListener('contextmenu', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
        document.body.removeEventListener('contextmenu', this.closeMenu)
      }
    }
  },
  computed: {
    activeName: {
      get: function () {
        let name = this.$route.fullPath
        if (name.includes('?')) {
          name = name.substr(0, name.indexOf('?'))
        }
        return name
      },
      set: function () { }
    },
    tabsNavs () {
      return this.tabsNavList
    },
    ...mapGetters(['lastBreadCrumb', 'sidebar'])
  },
  methods: {
    closeAll () {
      this.openTab('全部孕妈', 'all', '/mother/all', {})
      this.tabsNavs.map((i) => {
        if (i.path !== '/mother/all') {
          this.closeTab(i.path, null, null, null, false)
        }
      })
      this.closeMenu()
    },
    closeDirection (bool) {
      const res = this.tabsNavs.reduce(
        (res, i) => {
          if (res.find) {
            res.right.push(i.path)
          } else {
            if (i.path === this.clickPath) {
              res.find = true
            } else {
              res.left.push(i.path)
            }
          }
          return res
        },
        { left: [], right: [], find: false }
      )
      res[bool ? 'left' : 'right'].map((i) =>
        this.closeTab(i, null, null, null, false)
      )
      this.closeMenu()
    },
    closeMenu () {
      this.visible = false
    },
    closeOther () {
      this.tabsNavs.map((i) => {
        if (i.path !== this.clickPath) {
          this.closeTab(i.path, null, null, null, false)
        }
      })
      this.closeMenu()
    },
    close () {
      this.closeTab(this.clickPath, null, null, null, false)
      this.closeMenu()
    },
    openMenu (e, item) {
      if (e.target.id) {
        this.clickPath = e.target.id.substr(4)
        this.top = e.clientY
        this.left = e.clientX
        this.visible = true
      } else {
        this.visible = false
      }
    },
    removeTab (closeFullPath) {
      this.closeTab(closeFullPath)
    },
    handleClick (tab, event) {
      const item = this.tabsNavList.find((i) => i.path === tab.name)
      this.$router.push({ path: tab.name, query: item.query }).catch((e) => { })
    }
  },
  created () {
    this.tabsNavList = JSON.parse(window.sessionStorage.getItem('tabsNavList'))
    window.addEventListener('tabsNavList', () => {
      this.tabsNavList = JSON.parse(
        window.sessionStorage.getItem('tabsNavList')
      )
    })
  },
  destroyed () {
    window.removeEventListener(
      'tabsNavList',
      () => {
        this.tabsNavList = JSON.parse(
          window.sessionStorage.getItem('tabsNavList')
        )
      },
      true
    )
  }
}
</script>

<style lang="scss" scoped>
.ui-tabsNav {
  height: 46px;
  margin: 0 24px 0 24px;
  overflow: hidden;
  background: #fff;
  .contextmenu {
    width: 200px;
    margin: 0;
    background: rgb(33, 37, 43);
    color: #fff;
    z-index: 3000;
    position: fixed;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background-color: rgb(44, 49, 58);
        color: #fff;
      }
    }
  }
  ::v-deep .el-tabs__header {
    margin: 0;
    ::v-deep .el-tabs__nav-scroll {
      background-color: #ffffff;
      .el-tabs__nav {
        background-color: rgb(251, 251, 251);
      }
      ::v-deep .el-tabs__item {
        height: 46px;
        position: relative;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        padding: 13px 46px !important;
        line-height: normal;
      }
      ::v-deep .el-tabs__item.is-active {
        background-color: #ffffff;
        ::v-deep .el-icon-close {
          display: block;
        }
      }
      ::v-deep .el-tabs__item:hover {
        ::v-deep .el-icon-close {
          display: block;
        }
      }
      ::v-deep .el-icon-close {
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 14px;
        color: #000000;
      }
      ::v-deep .el-icon-close:before {
        -webkit-transform: none;
        transform: none;
      }
      ::v-deep .el-tabs__active-bar {
        bottom: 44px;
      }
    }
  }

  ::v-deep .el-tabs__nav-wrap::after {
    background-color: rgba($color: #000000, $alpha: 0);
  }
}

//fireFox兼容
.ui-tabsNav {
  ::v-deep .el-tabs__header {
    margin: 0;
    .el-tabs__nav-scroll {
      background-color: #ffffff;
      .el-tabs__nav {
        background-color: rgb(251, 251, 251);
      }
      .el-tabs__item {
        height: 46px;
        position: relative;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        padding: 13px 46px !important;
        line-height: normal;
      }
      .el-tabs__item.is-active {
        background-color: #ffffff;
        .el-icon-close {
          display: block;
        }
      }
      .el-tabs__item:hover {
        .el-icon-close {
          display: block;
        }
      }
      .el-icon-close {
        display: none;
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 14px;
        color: #000000;
      }
      .el-icon-close:before {
        -webkit-transform: none;
        transform: none;
      }
      .el-tabs__active-bar {
        bottom: 44px;
      }
    }
  }
  ::v-deep .el-tabs__nav-wrap::after {
    background-color: rgba($color: #000000, $alpha: 0);
  }
}
</style>