<template>
  <div class="ui-stepProcess">
    <div class="ui-stepProcess-process">
      <template v-for="(item,i) in stepNameList">
        <img class="ui-stepProcess-cicle" :key="'cicle'+i" :src="imgList[imgIsActive(i)]" />
        <div :style="{'border-color':(status>i+1?'#6DD400':'#dedede'),width: `calc((100% - ${line*stepNameList.length}px) / ${stepNameList.length-1})`}" v-if="i<stepNameList.length-1" class="ui-stepProcess-line" :key="'line'+i"></div>
      </template>
    </div>
    <div class="ui-stepProcess-textBox">
      <div :style="{color:(status>i?'#6DD400':'#555555'),'margin-right': i<stepNameList.length-1?`calc((100% - ${textBox*stepNameList.length}px) / ${stepNameList.length-1})`:''}" class="ui-stepProcess-text" v-for="(item,i) in stepNameList" :key="i">{{item}}</div>
    </div>
    <div class="ui-stepProcess-time">
      <div :style="{'margin-right': i<timeList.length-1?`calc((100% - ${time*stepNameList.length}px) / ${stepNameList.length-1})`:''}" class="ui-stepProcess-time-text" v-for="(item,i) in timeList" :key="i" v-show="status>i">{{item}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'stepProcess',
  props: {
    status: Number,
    stepNameList: Array,
    timeList: Array
  },
  data() {
    return {
      time: 136,
      textBox: 64,
      line: 30,
      imgList: [
        require('../../assets/img/order/6.png'),
        require('../../assets/img/order/7.png'),
        require('../../assets/img/order/8.png'),
        require('../../assets/img/order/9.png'),
        require('../../assets/img/order/10.png'),
        require('../../assets/img/order/1.png'),
        require('../../assets/img/order/2.png'),
        require('../../assets/img/order/3.png'),
        require('../../assets/img/order/4.png'),
        require('../../assets/img/order/5.png')
      ]
    }
  },
  computed: {

  },
  methods: {
    imgIsActive(num) {
      return num + 1 > this.status ? num : num + 5
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-stepProcess {
  display: inline-block;
  margin: 24px;
  width: calc(100% - 48px);
  height: 86px;
  .ui-stepProcess-process {
    padding: 0 72px;
    display: inline-block;
    width: 100%;
    height: 32px;
    .ui-stepProcess-cicle {
      width: 30px;
      height: 30px;
      display: inline-block;
      line-height: 30px;
    }
    .ui-stepProcess-line {
      border-top: 2px solid #dedede;
      outline: none;
      height: 5px;
      display: inline-block;
    }
  }
  .ui-stepProcess-textBox {
    padding: 0 55px;
    display: inline-block;
    width: 100%;
    height: 24px;
    .ui-stepProcess-text {
      display: inline-block;
      width: 64px;
      height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 500;
      line-height: 24px;
    }
  }
  .ui-stepProcess-time {
    padding: 6px 21px 0;
    display: inline-block;
    width: 100%;
    height: 30px;
    .ui-stepProcess-time-text {
      display: inline-block;
      width: 136px;
      height: 24px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #6dd400;
    }
  }
}
</style>
     