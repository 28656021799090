import Vue from 'vue'
//input只能输入正整数
Vue.directive('Int', {
    bind: function (el) {
        const input = el.getElementsByTagName('input')[0]
        input.onkeyup = function (e) {
            if (input.value.length === 1) {
                input.value = input.value.replace(/[^0-9]/g, '')
            } else {
                input.value = input.value.replace(/[^\d]/g, '')
            }
            trigger(input, 'input')
        }
        input.onblur = function (e) {
            if (input.value.length === 1) {
                input.value = input.value.replace(/[^0-9]/g, '')
            } else {
                input.value = input.value.replace(/[^\d]/g, '')
            }
            trigger(input, 'input')
        }
    }
})
//input保留两位小数
Vue.directive('Float2', {
    bind: function (el) {
        const input = el.getElementsByTagName('input')[0]
        input.onkeyup = function (e) {
            if (input.value.length === 1) {
                input.value = input.value.replace(/[^0-9]/g, '')
            } else {
                input.value = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?$/.test(input.value) ? input.value : input.value.toString().substr(0, input.value.length - 1)
            }
            trigger(input, 'input')
        }
        input.onblur = function (e) {
            if (input.value.length === 1) {
                input.value = input.value.replace(/[^0-9]/g, '')
            } else {
                input.value = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,2})?$/.test(input.value) ? input.value : input.value.toString().substr(0, input.value.length - 1)
            }
            trigger(input, 'input')
        }
    }
})
const trigger = (el, type) => {
    const e = document.createEvent('HTMLEvents')
    e.initEvent(type, true, true)
    el.dispatchEvent(e)
}