import Layout from '@/components/layout/left.vue'
const moduleList = [
  {
    name: 'mother',
    redirect: 'all',
    files: require.context(`@/views/mother`, true, /\.vue$/)
  },
  {
    name: 'sys',
    redirect: 'user',
    files: require.context(`@/views/sys`, true, /\.vue$/)
  },
  {
    name: 'monitor',
    redirect: 'heart',
    files: require.context(`@/views/monitor`, true, /\.vue$/)
  }
]
const getRouter = function (MODULE_NAME, redirect, files) {
  const router = {
    path: `/${MODULE_NAME}`,
    name: `/${MODULE_NAME}Link`,
    component: Layout,
    redirect: `/${MODULE_NAME}/${redirect}`,
    meta: {
      requireLogin: true,
      requirePermission: true,
    },
    children: [

    ]
  }
  files.keys().forEach(key => {
    const route = `/${MODULE_NAME}${key.substring(1, key.lastIndexOf('.'))}`
    const name = route.replace(/\/(\w)/g, (...args) => args[2] === 0 ? args[1] : args[1].toUpperCase())
    router.children.push({
      path: route,
      name: `${name}Link`,
      component: resolve => require(['@/views/' + MODULE_NAME + key.substr(1)], resolve),
      meta: {
        requireLogin: true,
        requirePermission: true,
      }
    })
  })
  return router
}

export default moduleList.map(({ name, redirect, files }) => getRouter(name, redirect, files))