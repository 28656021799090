<template>
  <el-dialog title="发送站内信息" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="600px" :before-close="dialogBeforeClose">
    <div class="ui-box-dialog-info">标题</div>
    <el-input class="ui-box-dialog-info-input" v-model="textarea"></el-input>
    <div class="ui-box-dialog-info">内容</div>
    <el-input class="ui-box-dialog-textarea" type="textarea" :rows="4" v-model="textarea">
    </el-input>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
      <el-button class="ui-box-submit" type="primary" @click="dialogBeforeClose">发 送</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'sendStationInfo',
  props: {
    tbId: String,
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      textarea: ''
    }
  },
  computed: {

  },
  methods: {
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .ui-box-dialog-info {
    margin-left: 57px;
    margin-right: 20px;
    width: 28px;
    height: 22px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
  }
  .ui-box-dialog-info-input {
    margin-bottom: 24px;
    width: 407px;
    height: 36px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    ::v-deep .el-input__inner,
    ::v-deep .el-input__icon {
      height: 34px;
      line-height: 34px;
      border: none;
      vertical-align: top;
    }
    &:focus {
      outline: none;
    }
  }
  .ui-box-dialog-textarea {
    display: inline-block;
    width: 407px;
    vertical-align: top;
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
</style>