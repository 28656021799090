<template>
  <div class="ui-order-operateInfo">
    <div class="ui-order-operateInfo-title">操作信息</div>
    <table class="ui-order-operateInfo-table">
      <tr>
        <th>操作者</th>
        <th>操作类型</th>
        <th>操作时间</th>
        <th>订单状态</th>
        <th>备注</th>
      </tr>
      <tr v-for="(i,index) in data.orderLogs" :key="'orderLogs'+index">
        <td>{{i.tbUserName}}</td>
        <td>{{i.tbOperType}}</td>
        <td>{{i.createTime}}</td>
        <td>{{data.tbIsBuyHire === 1 && i.tbState === 3? '待自提':statusMap[i.tbState]}}</td>
        <td>{{i.tbRemarks}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'operateInfo',
  props: {
    data: Object
  },
  data() {
    return {
      statusList: [
        {
          id: '1',
          name: '待付款'
        },
        {
          id: '2',
          name: '待发货'
        },
        {
          id: '3',
          name: '已发货'
        },
        {
          id: '4',
          name: '已完成'
        },
        {
          id: '5',
          name: '已评价'
        },
        {
          id: '6',
          name: '已关闭'
        }
      ]
    }
  },
  computed: {
    statusMap() {
      return this.statusList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.ui-order-operateInfo {
  margin: 0 24px 40px;
  width: calc(100% - 48px);
  height: auto;
  .ui-order-operateInfo-title {
    margin-left: 4px;
    margin-bottom: 10px;
    width: 180px;
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
  }
  .ui-order-operateInfo-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    border: 1px solid #dae9ff;
    border-collapse: collapse;
    tr {
      border: 1px solid #dae9ff;
      height: 54px;
      line-height: 54px;
      th {
        background: #f2f6fc;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        text-align: center;
        border: 1px solid #dae9ff;
      }
      td {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        border: 1px solid #dae9ff;
      }
    }
  }
}
</style>
     