/**
 *  数据去抖
 */
export function debounceUtils (func, delay) {
    let timer
    return function (...args) {
        if (timer) {
            clearTimeout(timer)
        }
        timer = setTimeout(() => {
            func.apply(this, args)
        }, delay)
    }
}
/**
 *  数据截流
 */
export function throttle (func, delay) {
    let last = 0
    return function () {
        var curr = +new Date()
        if (curr - last > delay) {
            func.apply(this, arguments)
            last = curr
        }
    }
}

export class Scheduler {
    constructor(max = 3) {
        this.awaitArr = []
        this.count = 0
        this.max = max
    }
    async add (promise) {
        if (this.count < this.max) {
            await new Promise(resolve => this.awaitArr.push(resolve))
        }
        this.count++
        const res = await promise()
        this.count--
        this.awaitArr.length && this.awaitArr.shift()()
        return res
    }
}



// 树转数组树
function treeTolistTree (arr) {
    var res = [];
    !(function fn (arr) {
        arr.forEach(function (item, index) {
            res.push(item);
            if (item.children && item.children.length > 0) {
                fn(item.children);
            }
        });
    })(arr);
    return res;
}

//添加按钮权限
export function addBtnPermission (treelist, to, next) {
    const menuList = treeTolistTree(treelist);
    const path = to.path;
    let btnPermission = [];
    menuList.forEach(({ tbPath, children }) => {
        if (path === tbPath) {
            btnPermission = btnPermission.concat(children.filter(i => i.tbType === 3) || []);
        }
    });
    to.meta["btnPermission"] = btnPermission;
    next && next();
}
