<template>
  <div class="ui-order-baseInfo">
    <div class="ui-order-baseInfo-title">基本信息</div>
    <table class="ui-order-baseInfo-table">
      <tr>
        <th>订单编号</th>
        <th>发货单流水号</th>
        <th>用户账号</th>
        <th>支付方式</th>
      </tr>
      <tr>
        <td>{{getData(data.tbOrderCode)}}</td>
        <td>{{getData(data.tbDeliverOrderCode)}}</td>
        <td>{{getData(data.tbUserPhone)}}</td>
        <td>{{getData(payTypeMap[data.tbPayType])}}</td>
      </tr>
      <tr>
        <th>订单类型</th>
        <th>配送方式</th>
        <th>自动确认收货时间(天)</th>
        <th>活动信息</th>
      </tr>
      <tr>
        <td>{{getData(data.tbChannel===1?'安卓':data.tbChannel===2?'ios':'小程序')}}</td>
        <td style="padding:10px 0">
          <div class="ui-orderExpress" v-for="(i,index) in getOrderExpress(data.orderExpress)" :key="'orderExpress'+index"> {{i}}</div>
        </td>
        <td>{{getData(data.autoReceivingTime)}}</td>
        <td>{{getData(data.activityDetails)}}</td>
      </tr>
      <tr>
        <th colspan="4">用户定位所在医院</th>
      </tr>
      <tr>
        <!-- <td colspan="4" @click="openTab('修改医院','hospitalDetail',`/hospitalManage/hospitalList/hospitalDetail`,{tbId:data.tbHospitalId})">{{getData(data.tbHospitalName)}}</td> -->
        <td colspan="4">{{getData(data.tbHospitalName)}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'baseInfo',
  props: {
    data: Object,
    status: Number
  },
  computed: {
    payTypeMap() {
      return this.payTypeList.reduce((res, { id, name }) => {
        res[id] = name
        return res
      }, {})
    },
    ...mapGetters([
      'lastBreadCrumb'
    ])
  },
  data() {
    return {
      payTypeList: [
        {
          id: '0',
          name: '未支付'
        },
        {
          id: '1',
          name: '微信'
        },
        {
          id: '2',
          name: '支付宝'
        },
        {
          id: '3',
          name: '苹果支付'
        },
        {
          id: '4',
          name: '虚拟币支付'
        },
        {
          id: '5',
          name: '已支付(0元支付)'
        },
        {
          id: '6',
          name: '微信'
        },
        {
          id: '7',
          name: '会员赠送'
        }
      ]
    }
  },
  methods: {
    getOrderExpress(orderExpress) {
      if (!orderExpress) {
        return ['暂无']
      }
      let list = orderExpress.split(',')
      list = list.map((i, j) => {
        i = `包裹${j + 1}:${i.replace(':', ' ')}\n`
        return i
      })
      return list
    },
    getData(val) {
      return val ? val : '暂无'
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-order-baseInfo {
  margin: 0 24px 40px;
  width: calc(100% - 48px);
  height: auto;
  .ui-order-baseInfo-title {
    margin-left: 4px;
    margin-bottom: 10px;
    width: 80px;
    height: 28px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
  }
  .ui-order-baseInfo-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    border: 1px solid #dae9ff;
    border-collapse: collapse;
    tr {
      border: 1px solid #dae9ff;
      height: 54px;
      line-height: 54px;
      th {
        background: #f2f6fc;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #000000;
        text-align: center;
        border: 1px solid #dae9ff;
      }
      td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #dae9ff;
        .ui-orderExpress {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}
</style>
     