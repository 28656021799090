<template>
  <el-dialog title="修改收货人信息" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="674px" :before-close="dialogBeforeClose">
    <el-form ref="form" class="ui-box-dialog-from" label-position="right" :model="data" label-width="130px">
      <el-form-item label="收货人">
        <el-input class="ui-box-dialog-from-input" v-model="data.tbLinkMan"></el-input>
      </el-form-item>
      <el-form-item label="手机号码">
        <el-input class="ui-box-dialog-from-input" v-model="data.tbLinkManPhone"></el-input>
      </el-form-item>
      <el-form-item label="所在地区">
        <el-select class="ui-box-dialog-from-input width145" v-model="data.tbProvinceId" placeholder="">
          <el-option v-for="item in provinceList" :key="item.id" :label="item.areaName" :value="item.id"></el-option>
        </el-select>
        <el-select class="ui-box-dialog-from-input width145" v-model="data.tbCityId" placeholder="">
          <el-option v-for="item in cityList" :key="item.id" :label="item.areaName" :value="item.id"></el-option>
        </el-select>
        <el-select class="ui-box-dialog-from-input width145" v-model="data.tbAreaId" placeholder="">
          <el-option v-for="item in areaList" :key="item.id" :label="item.areaName" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input class="width439" type="textarea" :rows="4" v-model="data.tbDetailedAddress">
        </el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
      <el-button class="ui-box-submit" type="primary" @click="save">确 认</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'updateConsigneeInfo',
  props: {
    data: Object,
    tbId: String,
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      options: [],
      cityList: [],
      areaList: [],
      provinceList: [],
      form: {
        name: ''
      }
    }
  },
  watch: {
    'data.tbProvinceId': function (val) {
      this.provinceChange(val)
    },
    'data.tbCityId': function (val) {
      this.cityChange(val)
    }
  },
  computed: {

  },
  methods: {
    save () {
      const form = {
        tbId: this.$route.query.tbId,
        tbProvinceName: this.provinceList.find(i => i.id === this.data.tbProvinceId).areaName,
        tbProvinceId: this.data.tbProvinceId,
        tbCityName: this.cityList.find(i => i.id === this.data.tbCityId).areaName,
        tbCityId: this.data.tbCityId,
        tbAreaName: this.areaList.find(i => i.id === this.data.tbAreaId).areaName,
        tbAreaId: this.data.tbAreaId,
        tbDetailedAddress: this.data.tbDetailedAddress,
        tbLinkMan: this.data.tbLinkMan,
        tbLinkManPhone: this.data.tbLinkManPhone
      }
      this.$http.post('/order/updateOrderShippingAddress', form)
        .then(res => {
          if (res.code === 1000) {
            this.$message.success('修改成功')
            this.dialogBeforeClose()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    cityChange (val) {
      if (val) {
        if (this.areaList.length !== 0) {
          this.data.tbAreaId = ''
        }
        this.getAreaList(val)
      } else {
        this.areaList = []
      }
    },
    provinceChange (val) {
      if (val) {
        if (this.cityList.length !== 0) {
          this.data.tbCityId = ''
        }
        this.getCityList(val)
      } else {
        this.cityList = []
      }
    },
    getCityList (id) {
      this.$http.post('/area/getTbAreaByCity', { id: id })
        .then(res => {
          if (res.code === 1000) {
            this.cityList = res.data.list
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getAreaList (id) {
      this.$http.post('/area/getTbAreaByArea', { id: id })
        .then(res => {
          if (res.code === 1000) {
            this.areaList = res.data.list
          } else {
            this.$message.error(res.message)
          }
        })
    },
    getProvinceList () {
      this.$http.get('/area/getTbAreaByProvince', {})
        .then(res => {
          if (res.code === 1000) {
            this.provinceList = res.data.list
          } else {
            this.$message.error(res.message)
          }
        })
    },

    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {
    this.getProvinceList()
  }
}
</script>

<style lang="scss" scoped>
.ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .ui-box-dialog-from {
    .width439 {
      width: 439px;
    }
    .ui-box-dialog-from-input {
      &.width145 {
        margin-right: 2px;
        width: 145px;
      }
      width: 188px;
      height: 36px;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      ::v-deep .el-input__inner,
      ::v-deep .el-input__icon {
        height: 34px;
        line-height: 34px;
        border: none;
        vertical-align: top;
      }
      &:focus {
        outline: none;
      }
    }
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
</style>