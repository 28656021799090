<template>
  <div class="ui-order-costInfo">
    <div class="ui-order-costInfo-title">费用信息</div>
    <table class="ui-order-costInfo-table">
      <tr>
        <th>商品原价合计</th>
        <th>运费</th>
        <th>折扣金额</th>
        <th :colspan="data.tbState===1?1:2">积分抵扣</th>
      </tr>
      <tr>
        <td>{{getData(data.costDetailed&&data.costDetailed.goodsOriginalPrice)}}</td>
        <td>{{getData(data.costDetailed&&data.costDetailed.tbFreight)}}</td>
        <td>{{getData(data.costDetailed&&data.costDetailed.discountPrice)}}<span class="ui-order-costInfo-table-detail" v-if="data&&data.costDetailed&&data.costDetailed.discountPrice>0" @click="dialogVisible=true">详情</span></td>
        <td :colspan="data.tbState===1?1:2">{{getData(data.costDetailed&&data.costDetailed.integral)}}</td>
      </tr>
      <tr>
        <th>优惠活动</th>
        <th>优惠券</th>
        <th>会员折扣</th>
        <th v-if="data.tbState===1">待付款金额</th>
        <th v-if="data.tbState!==1">其他抵扣</th>
        <th v-if="data.tbState!==1">实付款金额</th>
      </tr>
      <tr>
        <td>待开发</td>
        <td>{{getData(data.costDetailed&&data.costDetailed.coupon)}}</td>
        <!-- <td>{{getData(data.costDetailed&&data.costDetailed.coupon)}}<span class="ui-order-costInfo-table-detail" @click="dialogVisible3=true" v-if="data.costDetailed.coupon>0">详情</span></td> -->
        <td>{{getData(data.costDetailed&&data.costDetailed.member)}}</td>
        <td v-if="data.tbState===1">{{getData(data.costDetailed&&data.costDetailed.actualPrice)}}</td>
        <td v-if="data.tbState!==1">{{getData(data.costDetailed&&data.costDetailed.tbReductionPrice)}}</td>
        <td v-if="data.tbState!==1">{{getPriceData(data.costDetailed&&data.costDetailed.actualPrice)}}</td>
      </tr>
    </table>
    <el-dialog title="折扣金额详情" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="816px" :before-close="dialogBeforeClose">
      <div class="ui-box-costInfo-tableList">
        <el-table :data="tableList2" border style="width: 100%" :header-cell-style="{background: '#FFFFFF',color: '#000000','font-weight': 500,'font-family': 'Microsoft YaHei'}">
          <el-table-column align="center" width="239" prop="tbGoodsName" label="商品名称">
          </el-table-column>
          <el-table-column align="center" width="135" prop="tbSpecificationsName" label="规格">
          </el-table-column>
          <el-table-column align="center" width="97" prop="tbOriginalPrice" label="原价">
          </el-table-column>
          <el-table-column align="center" width="89" prop="tbDiscountPrice" label="折扣价">
          </el-table-column>
          <el-table-column align="center" width="78" prop="tbNumber" label="数量">
          </el-table-column>
          <el-table-column align="center" width="137" prop="6" label="折扣金额">
            <template slot-scope="{row}">￥{{row.tbOriginalPrice-row.tbDiscountPrice}}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="ui-order-costInfo-all">合计折扣金额：￥{{getData(data.costDetailed&&data.costDetailed.discountPrice)}}</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="ui-box-cancel" @click="dialogBeforeClose">取 消</el-button>
        <el-button class="ui-box-submit" type="primary" @click="dialogVisible = false">确 认</el-button>
      </span>
    </el-dialog>

    <el-dialog title="优惠活动详情" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible2" width="984px" :before-close="dialogBeforeClose2">
      <div class="ui-box-costInfo-tableList">
        <el-table :data="tableList2" border style="width: 100%" :header-cell-style="{background: '#FFFFFF',color: '#000000','font-weight': 500,'font-family': 'Microsoft YaHei'}">
          <el-table-column align="center" prop="tbGoodsName" label="商品名称">
          </el-table-column>
          <el-table-column align="center" width="115" prop="tbSpecificationsName" label="规格">
          </el-table-column>
          <el-table-column align="center" width="71" prop="tbOriginalPrice" label="原价">
          </el-table-column>
          <el-table-column align="center" width="76" prop="tbOriginalPrice" label="折扣价">
          </el-table-column>
          <el-table-column align="center" width="68" prop="tbNumber" label="数量">
          </el-table-column>
          <el-table-column align="center" width="126" prop="6" label="优惠活动">
          </el-table-column>
          <el-table-column align="center" width="96" prop="7" label="优惠金额">
          </el-table-column>
          <el-table-column align="center" width="123" prop="8" label="优惠合计金额">
          </el-table-column>
        </el-table>
      </div>
      <div class="ui-order-costInfo-all">合计优惠金额：￥50</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="ui-box-cancel" @click="dialogBeforeClose2">取 消</el-button>
        <el-button class="ui-box-submit" type="primary" @click="dialogVisible2 = false">确 认</el-button>
      </span>
    </el-dialog>

    <el-dialog title="优惠券金额详情" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible3" width="851px" :before-close="dialogBeforeClose3">
      <div class="ui-box-costInfo-tableList">
        <table class="ui-order-costInfo-table2">
          <tr>
            <th style="width:221px">商品名称</th>
            <th style="width:115px">规格</th>
            <th style="width:71px">原价</th>
            <th style="width:76px">折扣价</th>
            <th style="width:68px">数量</th>
            <th style="width:126px">优惠券</th>
            <th style="width:96px">优惠金额</th>
          </tr>
          <tr v-for="(item,i) in tableList4" :key="i">
            <td style="width:221px">{{item['1']}}</td>
            <td style="width:115px">{{item['2']}}</td>
            <td style="width:71px">{{item['3']}}</td>
            <td style="width:76px">{{item['4']}}</td>
            <td style="width:68px">{{item['5']}}</td>
            <td style="width:126px" rowspan="2" v-if="i%2===0">{{item['6']}}</td>
            <td style="width:96px" rowspan="2" v-if="i%2===0">{{item['7']}}</td>
          </tr>
        </table>
      </div>
      <div class="ui-order-costInfo-all">合计优惠券金额：￥10</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="ui-box-cancel" @click="dialogBeforeClose3">取 消</el-button>
        <el-button class="ui-box-submit" type="primary" @click="dialogVisible3 = false">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'costInfo',
  props: {
    data: Object
  },
  data () {
    return {
      dialogVisible3: false,
      dialogVisible2: false,
      dialogVisible: false,
      tableList: [
        {
          '1': '￥670',
          '2': '￥0',
          '3': '-￥110',
          '4': '￥0'
        }
      ],

      tableList1: [
        {
          '1': '-￥50',
          '2': '-￥10',
          '3': '￥0',
          '4': '￥500'
        }
      ],
      tableList3: [
        {
          '1': '贝亲奶瓶贝亲奶瓶贝亲奶瓶',
          '2': '红色-120ml',
          '3': '￥60',
          '4': '￥50',
          '5': '10',
          '6': '优惠活动名称',
          '7': '￥5',
          '8': '￥50'
        },
        {
          '1': '贝亲奶瓶贝亲奶瓶贝亲奶瓶贝亲奶瓶贝亲奶瓶',
          '2': '红色-180ml',
          '3': '￥70',
          '4': '￥60',
          '5': '1',
          '6': '暂无活动',
          '7': '￥0',
          '8': '￥0'
        }
      ],
      tableList4: [
        {
          '1': '贝亲奶瓶贝亲奶瓶贝亲奶瓶',
          '2': '红色-120ml',
          '3': '￥60',
          '4': '￥50',
          '5': '10',
          '6': '满299减10',
          '7': '￥10'
        },
        {
          '1': '贝亲奶瓶贝亲奶瓶贝亲奶瓶贝亲奶瓶贝亲奶瓶',
          '2': '红色-180ml',
          '3': '￥70',
          '4': '￥60',
          '5': '1',
          '6': '满299减10',
          '7': '￥10'
        }
      ]
    }
  },
  computed: {
    tableList2 () {
      return this.data.orderGoods
    }
  },
  methods: {
    getPriceData (val) {
      if (val || val === 0) {
        return val
      } else {
        return '暂无'
      }
    },
    getData (val) {
      return val ? val : '暂无'
    },
    dialogBeforeClose () {
      this.dialogVisible = false
    },
    dialogBeforeClose2 () {
      this.dialogVisible2 = false
    },
    dialogBeforeClose3 () {
      this.dialogVisible3 = false
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-order-costInfo {
  margin: 0 24px 40px;
  width: calc(100% - 48px);
  height: auto;
  .ui-order-costInfo-title {
    margin-left: 4px;
    margin-bottom: 10px;
    width: 180px;
    height: 28px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
  }
  .ui-order-costInfo-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    border: 1px solid #dae9ff;
    border-collapse: collapse;
    tr {
      border: 1px solid #dae9ff;
      height: 54px;
      line-height: 54px;
      th {
        background: #f2f6fc;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #000000;
        text-align: center;
        border: 1px solid #dae9ff;
      }
      td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #dae9ff;
        .ui-order-costInfo-table-detail {
          display: inline-block;
          margin-left: 44px;
          width: 28px;
          height: 22px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1890ff;
          line-height: 22px;
          cursor: pointer;
        }
      }
    }
  }
  .ui-order-costInfo-table2 {
    width: 100%;
    border: 1px solid #f1f1f1;
    border-collapse: collapse;
    tr {
      border: 1px solid #f1f1f1;
      th {
        text-align: center;
        line-height: 41px;
        height: 41px;
        vertical-align: middle;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #000000;
        border: 1px solid #f1f1f1;
      }
      td {
        text-align: center;
        padding: 15px 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #f1f1f1;
        &:first-child {
          text-align: left;
          padding: 15px 25px;
        }
      }
    }
  }
  ::v-deep .ui-box-dialog {
    border-radius: 12px;
    ::v-deep .el-dialog__title {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 500;
      color: #333333;
      line-height: 16px;
    }
    ::v-deep .el-dialog__headerbtn {
      &:focus {
        outline: none;
      }
    }
    .ui-box-costInfo-tableList {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      .ui-box-tableList-img {
        width: 51px;
        height: 51px;
        background: #d8d8d8;
        margin-left: 13px;
      }
    }
    .ui-order-costInfo-all {
      width: 100%;
      height: 22px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #000000;
      line-height: 22px;
      margin-top: 15px;
      text-align: right;
    }
    .ui-box-cancel {
      padding: 7px 26px;
      border-radius: 3px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #555555;
      line-height: 22px;
      &:focus {
        outline: none;
      }
    }
    .ui-box-submit {
      padding: 7px 26px;
      background: #3d7fff;
      border-radius: 3px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
      &:focus {
        outline: none;
      }
    }
  }
}
</style>
     