import { render, staticRenderFns } from "./costInfo.vue?vue&type=template&id=70af12e7&scoped=true"
import script from "./costInfo.vue?vue&type=script&lang=js"
export * from "./costInfo.vue?vue&type=script&lang=js"
import style0 from "./costInfo.vue?vue&type=style&index=0&id=70af12e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70af12e7",
  null
  
)

export default component.exports