<template>
  <div class="ui-order-consigneeInfo">
    <div class="ui-order-consigneeInfo-title">{{data.tbIsExtract===1?'提货人':'收货人'}}信息</div>
    <table class="ui-order-consigneeInfo-table">
      <tr>
        <th>{{data.tbIsExtract===1?'提货人':'收货人'}}</th>
        <th>手机号码</th>
        <th>收货地址</th>
      </tr>
      <tr>
        <td>{{getData(data.orderShipping&&data.orderShipping.tbLinkMan)}}</td>
        <td>{{getData(data.orderShipping&&data.orderShipping.tbLinkManPhone)}}</td>
        <td>{{getData(data.orderShipping&&data.orderShipping.tbUserAddress)}}</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'consigneeInfo',
  props: {
    data: Object
  },
  data() {
    return {

    }
  },
  methods: {
    getData(val) {
      return val ? val : '暂无'
    }

  }
}
</script>

<style lang="scss" scoped>
.ui-order-consigneeInfo {
  margin: 0 24px 40px;
  width: calc(100% - 48px);
  height: auto;
  .ui-order-consigneeInfo-title {
    margin-left: 4px;
    margin-bottom: 10px;
    width: 180px;
    height: 28px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
  }
  .ui-order-consigneeInfo-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    border: 1px solid #dae9ff;
    border-collapse: collapse;
    tr {
      border: 1px solid #dae9ff;
      height: 54px;
      line-height: 54px;
      th {
        background: #f2f6fc;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #000000;
        text-align: center;
        border: 1px solid #dae9ff;
      }
      td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #dae9ff;
      }
    }
  }
}
</style>
     