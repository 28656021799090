<template>
  <div class="ui-order-serviceTicket">
    <div class="ui-order-serviceTicket-title">{{hireLog.tbType===2?'换货定损':'还货定损'}}</div>
    <table class="ui-order-serviceTicket-table">
      <tr>
        <th>处理人类型</th>
        <td>{{getName(hireLog.tbUserType)}}</td>
      </tr>
      <tr>
        <th>处理人名称</th>
        <td>{{hireLog.tbUserName}}</td>
      </tr>
      <tr>
        <th>定损结果</th>
        <td>{{getIsDamage(hireLog.tbIsDamage)}}</td>
      </tr>
      <tr>
        <th>定损金额</th>
        <td>￥{{hireLog.tbDamagePrice}}</td>
      </tr>
      <tr>
        <th>定损时间</th>
        <td>{{hireLog.createTime}}</td>
      </tr>
      <tr>
        <th>定损图片</th>
        <td>
          <el-image v-for="url in hireLog.tbPicPath" :key="url" class="ui-order-serviceTicket-img" :src="url" alt="" :preview-src-list="[url]"></el-image>
        </td>
      </tr>
      <tr>
        <th>定损备注</th>
        <td>
          {{hireLog.tbRemarks}}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'damageInfo',
  props: {
    hireLog: Object
  },
  data () {
    return {
      damageList: [
        { id: 1, name: '人为损坏' },
        { id: 2, name: '正常' },
        { id: 3, name: '自然损坏' }
      ]
    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {
    getIsDamage (val) {
      if (val == 1) {
        return '人为损坏'
      } else if (val == 2) {
        return '完好'
      } else {
        return '自然损坏'
      }
    },
    getName (type) {
      if (type == 0) {
        return '管理员'
      } else if (type == 2) {
        return '医生'
      } else if (type == 3) {
        return '自提点'
      }
    }
  },
  components: {

  }
}
</script>

<style lang="scss" scoped>
.ui-order-serviceTicket {
  margin: 0 24px 40px;
  width: calc(100% - 48px);
  height: auto;
  .ui-order-serviceTicket-title {
    margin-left: 4px;
    margin-bottom: 10px;
    width: 180px;
    height: 28px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
  }
  .ui-order-serviceTicket-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    border: 1px solid #dae9ff;
    border-collapse: collapse;
    margin-bottom: 20px;
    &.table-input {
      ::v-deep .el-input {
        width: 620px;
      }
    }
    tr {
      border: 1px solid #dae9ff;
      height: 54px;
      line-height: 54px;
      th {
        background: #f2f6fc;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #000000;
        text-align: left;
        padding-left: 10px;
        border: 1px solid #dae9ff;
        width: 400px;
      }
      td {
        .ui-order-serviceTicket-img {
          margin: 20px;
          width: 80px;
          height: 80px;
          background: #d8d8d8;
          display: inline-block;
          vertical-align: top;
        }
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #dae9ff;
        text-align: left;
        padding: 0 10px;
        .ui-order-status-button {
          margin-left: 60px;
          height: 32px;
          background: #ffffff;
          border-radius: 2px;
          padding: 5px 16px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}
.ui-order-serviceTicket {
  ::v-deep .ui-box-dialog {
    border-radius: 12px;
    height: 50%;
    text-align: center;
    .el-icon-close:focus,
    .el-dialog__headerbtn:focus {
      outline: none;
    }
    .el-dialog__body {
      overflow: auto;
      height: calc(100% - 130px);
      .videoBox {
        width: 100%;
        height: calc(100% - 11px);
        &:focus {
          outline: none;
        }
      }
    }
  }
}
</style>
     