<template>
  <el-dialog title="租赁退款" top="30vh" custom-class="ui-box-dialog" :visible.sync="dialogVisible" width="600px" :before-close="dialogBeforeClose">
    <el-form ref="form" :model="form" :rules="rules" label-width="100px">
      <el-form-item label="订单结算方式" prop="tbType">
        <el-radio v-model="form.tbType" :label="1">正常结算</el-radio>
        <el-radio v-model="form.tbType" :label="2">直接关闭</el-radio>
      </el-form-item>
      <template v-if="form.tbType===1">
        <el-form-item label="提货时间" v-if="!data.tbSignTime" prop="tbSignTime">
          <el-date-picker v-model="form.tbSignTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择提货时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="归还时间" prop="tbReturnTime">
          <el-date-picker v-model="form.tbReturnTime" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择归还时间"></el-date-picker>
        </el-form-item>
        <el-form-item label="定损类型" prop="tbIsDamage">
          <el-radio v-model="form.tbIsDamage" :label="1">人为损坏</el-radio>
          <el-radio v-model="form.tbIsDamage" :label="2">完好</el-radio>
          <el-radio v-model="form.tbIsDamage" :label="3">自然损坏</el-radio>
        </el-form-item>
        <el-form-item label="定损金额" prop="tbDamagePrice" v-if="form.tbIsDamage===1">
          <el-input class="ui-box-dialog-textarea" v-model="form.tbDamagePrice"></el-input>
        </el-form-item>
      </template>
      <el-form-item label="退款金额" prop="tbPrice">
        <el-input class="ui-box-dialog-textarea" v-model="form.tbPrice"></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="tbRemarks">
        <el-input class="ui-box-dialog-textarea" type="textarea" :rows="4" v-model="form.tbRemarks"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="auditHireRefund">确 认</el-button>
        <el-button @click="dialogBeforeClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'hireRefund',
  props: {
    data: Object,
    tbId: String,
    dialogVisible: {
      type: Boolean
    }
  },
  data () {
    return {
      form: {
        tbIsExtract: '',
        tbOrderId: '',
        tbType: 1,
        tbSignTime: '',
        tbReturnTime: '',
        tbPrice: '',
        tbRemarks: '',
        tbIsDamage: 2,
        tbDamagePrice: 0,
      },
      rules: {
        tbPrice: [
          { required: true, message: '请输入退款金额', trigger: 'blur' }
        ],
        tbSignTime: [
          { required: true, message: '请选择提货时间', trigger: 'change' }
        ],
        tbReturnTime: [
          { required: true, message: '请选择归还时间', trigger: 'change' }
        ]
      }
    }
  },
  watch: {
    "form.type": function (val) {
      this.$refs.form.clearValidate()
    },
    dialogVisible (val) {
      this.$refs.form.clearValidate()
      if (val) {
        this.form = {
          bIsExtract: '',
          tbOrderId: '',
          tbType: 1,
          tbSignTime: '',
          tbReturnTime: '',
          tbPrice: '',
          tbRemarks: '',
          tbIsDamage: 2,
          tbDamagePrice: 0,
        }
      }
    },
  },
  computed: {

  },
  methods: {
    auditHireRefund () {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.msgboxMixin(() => {
            this.form.tbIsExtract = this.data.tbIsExtract
            this.form.tbOrderId = this.data.tbId
            if (this.form.tbIsDamage !== 1) {
              this.form.tbDamagePrice = 0
            }
            this.$http.post('/returnOrder/auditHireRefund', this.form, 'json', 500000)
              .then(res => {
                if (res.code === 1000) {
                  this.dialogBeforeClose()
                  this.$message({ type: 'success', message: '退款成功' })
                } else {
                  this.$message.error(res.message)
                }
              })
          }, '确定给当前订单退款？')
        } else {
          return false
        }
      });
    },
    dialogBeforeClose () {
      this.$emit('update:dialogVisible', false)
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>
.ui-box-dialog {
  border-radius: 12px;
  ::v-deep .el-dialog__headerbtn {
    &:focus {
      outline: none;
    }
  }
  .ui-box-dialog-remark {
    width: 76px;
    height: 22px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    display: inline-block;
    vertical-align: top;
    .label {
      height: 40px;
      line-height: 40px;
    }
  }
  .ui-box-dialog-textarea {
    margin-bottom: 10px;
    display: inline-block;
    width: 407px;
    vertical-align: top;
  }
  .ui-box-cancel {
    padding: 7px 26px;
    border-radius: 3px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #555555;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
  .ui-box-submit {
    padding: 7px 26px;
    background: #3d7fff;
    border-radius: 3px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 22px;
    &:focus {
      outline: none;
    }
  }
}
</style>