<template>
  <div class="ui-order-hireInfo">
    <div class="ui-order-hireInfo-title">租赁信息</div>
    <table class="ui-order-hireInfo-table">
      <tr>
        <th>押金</th>
        <th>日租金</th>
        <th>总租金</th>
        <th>租期</th>
      </tr>
      <tr>
        <td>￥{{data.tbPrice}}</td>
        <td>￥{{data.tbRentPrice}}</td>
        <td>￥{{data.tbAllRentPrice}}</td>
        <td>{{data.tbHireLowerDays}}天</td>
      </tr>
      <tr>
        <th>起租日期</th>
        <th>到租日期</th>
        <th>还租日期</th>
        <th>已租天数</th>

      </tr>
      <tr>
        <td>{{data.tbHireStartTime}}</td>
        <td>{{data.tbHireUpperTime}}</td>
        <td>{{data.tbHireReturnTime?new Date(data.tbHireReturnTime).format('yyyy-MM-dd'):'未归还'}}</td>
        <td>{{data.hireDays}}天</td>
      </tr>
      <tr>
        <th>是否自提</th>
        <th>取货自提点</th>
        <th>换货自提点</th>
        <th>退货自提点</th>
      </tr>
      <tr>
        <td>{{tbIsExtract==1?'自提':'邮寄'}}</td>
        <td>{{data.tbDeliveryShopName}}</td>
        <td>{{data.tbExchangeShopName}}</td>
        <td>{{data.tbReturnShopName}}</td>
      </tr>
      <tr v-if="data.tbIsEarlyReturn==1">
        <th>提前归还最低租金</th>
        <th>提前归还违约比例</th>
        <th>是否提前归还</th>
        <th>提前归还违约金</th>
      </tr>
      <tr v-if="data.tbIsEarlyReturn==1">
        <td>{{data.tbHireLowerPrice}}元</td>
        <td>{{data.tbHireBreach}}%</td>
        <td>{{data.tbIsEarlyReturn==1?'是':'否'}}</td>
        <td>{{data.penalty}}元</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'hireInfo',
  props: {
    data: Object,
    tbIsExtract: Number
  },
  data() {
    return {

    }
  },
  methods: {
    getData(val) {
      return val ? val : '暂无'
    }

  }
}
</script>

<style lang="scss" scoped>
.ui-order-hireInfo {
  margin: 0 24px 40px;
  width: calc(100% - 48px);
  height: auto;
  .ui-order-hireInfo-title {
    margin-left: 4px;
    margin-bottom: 10px;
    width: 180px;
    height: 28px;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 500;
    color: #000000;
    line-height: 28px;
  }
  .ui-order-hireInfo-table {
    table-layout: fixed;
    text-align: center;
    width: 100%;
    border: 1px solid #dae9ff;
    border-collapse: collapse;
    tr {
      border: 1px solid #dae9ff;
      height: 54px;
      line-height: 54px;
      th {
        background: #f2f6fc;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #000000;
        text-align: center;
        border: 1px solid #dae9ff;
      }
      td {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        border: 1px solid #dae9ff;
      }
    }
  }
}
</style>
     