<template>
  <!-- 菜单组件 -->
  <div>
    <div v-for="item in leftMenuList" :key="item.id">
      <el-submenu :index="item.tbPath" v-if="item.tbType === 1">
        <template slot="title">
          <!-- <img v-if="item.icon" width="16" height="16" v-bind:src="require('../../assets/img/menu/' + iconActive(item.icon))" alt />
          <span style="margin-left: 20px;" slot="title">{{item.name}}</span> -->
          <span slot="title">{{item.tbName}}</span>
        </template>
        <MenuTree :leftMenuList="item.children"></MenuTree>
      </el-submenu>
      <el-menu-item v-else-if="item.tbType === 2" :index="item.tbPath">
        <!-- <img v-if="item.icon" width="16" height="16" v-bind:src="require('../../assets/img/menu/' + iconActive(item.icon))" alt />
        <span style="margin-left: 20px;" slot="title">{{item.name}}</span> -->
        <span slot="title">{{item.tbName}}</span>
      </el-menu-item>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MenuTree',
  props: {
    leftMenuList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    iconActive (icon) {
      const path = icon.substr(0, icon.length - 4)
      if (this.$route.fullPath.split('/').length < 3) {
        return this.$route.fullPath.lastIndexOf(path) + path.length === this.$route.fullPath.length && this.$route.fullPath.includes(path) ? path + '-activate.png' : icon
      } else {
        let fullPath = this.$route.fullPath.split('/')
        fullPath.shift()
        if (this.menuIsPop(fullPath)) {
          fullPath.pop()
        }
        fullPath.unshift('')
        fullPath = fullPath.join('/')
        return fullPath.lastIndexOf(path) + path.length === fullPath.length && fullPath.includes(path) ? path + '-activate.png' : icon
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
