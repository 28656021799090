<template>
  <div class="ui-breadCrumb">
    <div class="ui-hamburger-container">
      <!-- <div class="ui-hamburger-container" @click="toggleClick"> -->
      <img :src="breadCrumbImg" class="ui-hamburger" :class="{'is-active':sidebar}" />
    </div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item,i) in breadList" :key="i">{{item}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'breadCrumb',
  data () {
    return {
      levelList: [],
      breadCrumbImg: require('@/assets/img/icon/breadCrumb.png')
    }
  },
  computed: {
    breadList () {
      const pathList = this.$route.fullPath.split('/')
      pathList.shift()
      const res = this.recursive([...pathList], this.menuLeftTree)
      if (!res) {
        let name = pathList.pop()
        if (name.includes('?')) {
          name = name.substr(0, name.indexOf('?'))
        }
        const list = this.recursive(pathList, this.menuLeftTree)
        list.push(this.lastBreadCrumb[name])
        return list
      }
      return res
    },
    ...mapGetters([
      'lastBreadCrumb',
      'sidebar',
      'menuLeftTree'
    ])
  },

  methods: {
    recursive (pathList, routeList, breadList = []) {
      if (pathList.length === 0) {
        return breadList
      }
      if (!routeList) {
        return false
      }
      let path = pathList.shift()
      if (path.includes('?')) {
        path = path.substr(0, path.indexOf('?'))
      }
      const temp = routeList.find(i => i.tbPath.split('/').pop() === path)
      breadList.push(temp.tbName)
      return this.recursive(pathList, temp.children, breadList)
    },
    toggleClick () {
      this.$store.dispatch('toggleSideBars')
    }
  }
}
</script>

<style lang="scss">
.ui-breadCrumb {
  height: 50px;
  overflow: hidden;
  position: relative;
  .ui-hamburger-container {
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    padding: 0px 15px;
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    .ui-hamburger {
      display: inline-block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      &.is-active {
        transform: rotate(180deg);
      }
    }
  }
  .el-breadcrumb {
    margin-left: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
  }
}
</style>