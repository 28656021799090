import store from '@/store';
import { addBtnPermission } from "@/utils/common.js";
import NProgress from 'nprogress'; // Progress 进度条
import 'nprogress/nprogress.css'; // Progress 进度条样式
import Vue from 'vue';
import VueRouter from 'vue-router';
import routerList from './modules/index';
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/',
    name: 'defaultLink',
    component: () => import('../views/Index.vue'),
    redirect: '/mother/all',
    children: [
      ...routerList,
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      requireLogin: false,
      requirePermission: false
    }
  },
  {
    path: '/401',
    name: 'unPermissionLink',
    component: (resolve) => require(['@/views/401.vue'], resolve),
    meta: {
      requireLogin: true
    }
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})
router.beforeEach(async (to, from, next) => {
  if (to.matched.some((res) => res.meta.requireLogin)) {
    if (!window.sessionStorage.getItem('token')) {
      next({
        path: '/login'
      })
    }
    next()
  } else {
    next()
  }
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (to.matched.some((res) => res.meta.requireLogin)) {
    if (window.sessionStorage.getItem('token')) {
      if (to.matched.some((res) => res.meta.requirePermission)) {
        debugger
        if (!store.state.menuList || store.state.menuList.length === 0) {
          await store.dispatch('getMenuListByUser')
        }
        addBtnPermission(store.state.menuTree, to, next);
        if (!store.state.menuList.some((i) => i.tbPath == to.path)) {
          next({ path: '/401' })
        }
      }
      next && next()
    } else {
      next({ path: '/login' })
    }
  } else {
    next()
  }
})
router.afterEach((to, from) => {
  NProgress.done()
})
export default router
